import {
  Paper,
  Table,
  TableContainer,
  TableRow,
  TableBody,
  Typography,
  useTheme,
  Box,
  TableCell,
  OutlinedInput,
  InputAdornment,
  FormControl,
} from '@mui/material';
import React, { useState, useEffect, useMemo } from 'react';
import { useGetSponsorsQuery, bloqcibeApi } from '../../store/slices/apiSlice';
import { CustomTableRow, CustomTableHead, CustomTableHeadCell } from '../../components/@extended/CustomTable';
import moment from 'moment';
import RightArrow from '../../components/icons/RightArrow';
import { useNavigate } from 'react-router-dom';
import { SponsorOrganizationColumns } from './SponsorOrganizationColumns';
import { base64ToArrayBuffer } from '../../components/common/DocumentUpload';
import CreateSponsor from '../CreateSponsor/CreateSponsor';
import CustomCard from '../../components/@extended/CustomCard';
import SearchIcon from '@mui/icons-material/Search';

const SponsorOrganizationTable = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [logos, setLogos] = useState({});
  const [downloadOrganizationLogo] = bloqcibeApi.endpoints.downloadUserProfilePic.useLazyQuery();
  const [searchValue, setSearchValue] = useState('');

  const { data, isLoading } = useGetSponsorsQuery();

  useEffect(() => {
    const fetchLogos = async () => {
      if (data) {
        const newLogos = {};
        for (const sponsor of data) {
          if (sponsor.logoS3Key) {
            const result = await downloadOrganizationLogo({ s3Key: sponsor.logoS3Key });
            if (result.data) {
              const byteArray = base64ToArrayBuffer(result.data);
              const blob = new Blob([byteArray], { type: 'application/octet-stream' });
              const reader = new FileReader();
              reader.readAsDataURL(blob);
              reader.onloadend = () => {
                newLogos[sponsor.id] = reader.result;
                setLogos((prevLogos) => ({ ...prevLogos, ...newLogos }));
              };
            }
          }
        }
      }
    };
    fetchLogos();
  }, [data, downloadOrganizationLogo]);

  // Memoize the filtered data based on search input
  const filteredData = useMemo(() => {
    if (!data) return [];
    return data.filter((sponsor) => sponsor.orgnizationName.toLowerCase().includes(searchValue.toLowerCase()));
  }, [data, searchValue]);

  if (isLoading) {
    return <></>;
  }

  const columns = SponsorOrganizationColumns();
  const accStatus = (status) => {
    if (status === 'Pending') {
      return <Typography variant="body2" sx={{color:theme.palette.warning.main, fontWeight:600}}>{status}</Typography>
    } else if (status === 'active') {
      return <Typography variant="body2" sx={{color:theme.palette.success.main, fontWeight:600}}>Active</Typography>
    }
  }
  return (
    <>
      <CustomCard
        title={
          <Typography variant="h6" fontWeight={600} color={theme.palette.grey[800]}>
            Sponsor Organizations
          </Typography>
        }
        action={
          <Box sx={{ display: 'flex', columnGap: 2, alignItems: 'center' }}>
            <FormControl variant="outlined">
              <OutlinedInput
                id="outlined-adornment-password"
                type={'text'}
                placeholder={'Search'}
                size="small"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </FormControl>
            <CreateSponsor />
          </Box>
        }
      >
        <TableContainer sx={{ maxHeight: 550 }}>
          <Table stickyHeader aria-label="sticky table">
            <CustomTableHead>
              <TableRow>
                {columns.map((column) => (
                  <CustomTableHeadCell key={column.id} align={column.align} sx={{ ...column.style }}>
                    {column.label}
                  </CustomTableHeadCell>
                ))}
              </TableRow>
            </CustomTableHead>
            <TableBody>
              {filteredData && filteredData.length > 0 ? (
                filteredData.map((row) => (
                  <CustomTableRow
                    key={row.id}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    sx={{ cursor: 'pointer' }}
                    onClick={() => navigate(`/sponsorDisplay/${row.id}`)}
                  >
                    <TableCell>
                      {logos[row.id] ? (
                        <Box
                          component="img"
                          src={logos[row.id]}
                          alt={row.orgnizationName}
                          sx={{
                            width: 50,
                            height: 50,
                            objectFit: 'contain',
                            marginRight: 2,
                          }}
                        />
                      ) : (
                        <Box
                          sx={{
                            width: 50,
                            height: 50,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: theme.palette.grey[300],
                            marginRight: 2,
                            borderRadius: 1,
                          }}
                        >
                          <Typography variant="body1" color="textSecondary">
                            {row.orgnizationName[0]}
                          </Typography>
                        </Box>
                      )}
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">{row.orgnizationName}</Typography>
                      <Typography variant="body2">{row.orgnizationTag}</Typography>
                    </TableCell>
                    <TableCell>{row.contactEmail}</TableCell>
                    <TableCell>{row?.financeDetail?.status ? accStatus(row?.financeDetail?.status): accStatus('Pending')}</TableCell>
                    <TableCell>{moment(row.createdAt).format('DD/MM/YYYY')}</TableCell>
                    <TableCell align="right">
                      <RightArrow onClick={() => navigate(`/sponsorDisplay/${row.id}`)} />
                    </TableCell>
                  </CustomTableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={columns.length}>
                    <Typography variant="subtitle1" color="initial">
                      No Sponsor Organization Found!
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </CustomCard>
    </>
  );
};

export default SponsorOrganizationTable;
