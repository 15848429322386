import React, { useMemo } from 'react'
import CustomCard from '../../../components/@extended/CustomCard';
import { Box, CardContent, Divider, dividerClasses, Typography } from '@mui/material';
import { Pie } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { ConsoleLogger } from 'amazon-chime-sdk-js';
import { Grid } from 'amazon-chime-sdk-component-library-react';

const FinanceSiteBudgetPie = () => {
  const currentSite = useSelector((state)=> state.finance.currentSite)

  const totalExpenditure = useMemo(() => {
    let total = 0;
    if (!currentSite || !currentSite?.transactions) return total;

    for (const t of currentSite?.transactions) {
      total += t?.amount;
    }
    return total / 100;
  }, [currentSite]);

  const totalBudget = useMemo(() => {
    let total = 0;
    if (!currentSite || !currentSite?.events) return total;

    for (const ev of currentSite?.events) {
      for (const evpo of ev.eventPayoutDetails) {
        total += Number(evpo?.amount) * currentSite?.totalSubjectNumber
      }

      if (ev?.subjectPaymentAmount) {
        total += Number(ev?.subjectPaymentAmount) * currentSite?.totalSubjectNumber;
      }
    }
    return total;
  }, [currentSite])

  const data = useMemo(() => {
    return {
      chartData: {
        labels: ['Total Budget', 'Total Expenditure'],
        datasets: [
          {
            label: 'Cost',
            data: [totalBudget, totalExpenditure],
            backgroundColor: ['#A5D027', '#004078'],
            borderColor: ['#A5D027', '#004078'],
            borderWidth: 1,
          },
        ],
      },
      totalCost: totalExpenditure + totalBudget,
    };
  }, [totalExpenditure, totalBudget]);
  return (
    <CustomCard title="Site Budget" subHeader={'(All costs are in USD)'}>
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Pie data={data?.chartData} style={{ height: 350 }} />
        </Box>
        <Grid container>
          <Grid item xs={5.5}></Grid>
          <Grid item xs={0.5}></Grid>
          <Grid item xs={5.5}></Grid>
        </Grid>
        <Box
          sx={{
            display: 'flex',
            columnGap: 2,
            justifyContent: 'center',
            // flexDirection: 'column',
            pt: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              pt: 2,
            }}
          >
            <Typography variant="subtitle1" color="initial">
              Total Budget
            </Typography>
            <Typography variant="h5" color="initial">
              ${totalBudget}
            </Typography>
          </Box>
          <Box
            sx={{
              height: '100%',
              [`& .${dividerClasses.root}`]: {
                mx: 0.5,
              },
            }}
          >
            <Divider orientation="vertical" flexItem />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              pt: 2,
            }}
          >
            <Typography variant="subtitle1" color="initial">
              Total Expenditure
            </Typography>
            <Typography variant="h5" color="initial">
              ${totalExpenditure}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </CustomCard>
  );
}

export default FinanceSiteBudgetPie