import React, { useState } from 'react';
import {

  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Step,
  StepButton,
  Tab,
  Divider,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import CustomButton from '../../components/@extended/CustomButton';
import AddIcon from '@mui/icons-material/Add';
import CustomStepper from '../../components/@extended/CustomStepper';
import SponserDetails from './SponserDetails';
import AssignConfiguration from './AssignConfiguration';
import StyledTabs from '../../components/@extended/StyledTabs';
import BankDetailsForm from '../../components/common/BankDetailsForm';
import { useAddSponsorFinanceDetailMutation } from '../../store/slices/apiSlice';
import { openMessage } from '../../store/slices/showMessageSlice';
import { useDispatch } from 'react-redux';


function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}


const SponsorDialog = ({ open, onClose }) => {
  const [tabValue, setTabValue] = useState(0);
  const dispatch = useDispatch()
  const [sponsorId, setSponsorId] = useState(null);
  const [address, setAddress] = useState(null);
  const [addressError, setAddressError] = useState('');
  const [addressDetails, setAddressDetails] = useState();
  const [addSponsorFinanceDetail, {isLoading}] = useAddSponsorFinanceDetailMutation() 

  const handleTabChange = (event ,newValue) => {
    setTabValue(newValue);
  };

   const openMessageNotification = (message) => {
      dispatch(openMessage({ message: message.message, messageSeverity: message.type }));
    };
  
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

  function handleClose() {
    onClose();
    setAddress(null);
    setAddressDetails(null);
    setAddressError('');
    handleTabChange(null,0)
    setSponsorId(null)
  }

  const saveSponsorBankDetails = async (data) => {
    try {
      const payload = { ...data, businessType: 'company' };
      const res = await addSponsorFinanceDetail({
        payload: payload,
        sponsorId
      }).unwrap()
      if (res) {
        openMessageNotification({
          message: "Sponsor Bank details added successfully.",
          type: "success",
        });
        handleClose()
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Dialog 
    open={open} 
    onClose={() => {handleClose()}} 
    PaperProps={{
      sx: {
        maxWidth: '800px'
      }
    }}
    >
          <DialogTitle>Add Sponsor Organization</DialogTitle>
          {/* <IconButton
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            onClick={() => {handleClose()}}
          >
            <CloseIcon />
          </IconButton> */}
          <DialogContent sx={{pt:0 ,  width:{xs:700 , md:800}}}>
          <StyledTabs
            value={tabValue}
            onChange={handleTabChange}
            textColor="primary"
            indicatorColor="primary"
            variant="fullWidth"
            sx={{
              top: 0, pb:2
            }}
          >
            <Tab label="1. Organization Details" {...a11yProps(0)} />
            {/* <Tab label="2. Assign Configuration" {...a11yProps(1)}/>  */}
            <Tab label="2. Bank Details" {...a11yProps(1)}/> 

          </StyledTabs>
          <TabPanel value={tabValue} index={0}>
            <SponserDetails setSponsorId={setSponsorId} handleTabChange={handleTabChange} address={address} setAddress={setAddress} addressError={addressError} setAddressError={setAddressError} addressDetails={addressDetails} setAddressDetails={setAddressDetails} handleClose={handleClose} />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            {/* <AssignConfiguration handleClose={handleClose} sponsorId={sponsorId}/> */}
          <BankDetailsForm handleClose={handleClose} handleSave={saveSponsorBankDetails} isLoading={isLoading} />
          </TabPanel>
              {/* <SponserDetails setSponsorId={setSponsorId} handleTabChange={handleTabChange} address={address} setAddress={setAddress} addressError={addressError} setAddressError={setAddressError} addressDetails={addressDetails} setAddressDetails={setAddressDetails} handleClose={handleClose} /> */}

          </DialogContent>
    </Dialog>
  );
};

const CreateSponsor = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <CustomButton variant="contained" startIcon={<AddIcon />} onClick={handleOpen} size='small'>
      Add Sponsor Org.
      </CustomButton>
      <SponsorDialog open={open} onClose={handleClose} />
    </Box>
  );
};

export default CreateSponsor;
