import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useAddFinanceEntityMutation,
  useAssignFinanceSiteStudyMutation,
  useDeleteFinanceSiteStudyMutation,
  useGetFianceEntetiesMutation,
  useUpdateFinanceTrialStatusMutation,
  useUploadAccountCsvMutation,
  useUploadBudgetCsvMutation,
} from '../../store/slices/apiSlice';
import CustomButton from '../../components/@extended/CustomButton';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import AddIcon from '@mui/icons-material/Add';
import { VisuallyHiddenInput } from '../../components/common/DocumentUpload';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { openMessage } from '../../store/slices/showMessageSlice';
import { useNavigate } from 'react-router-dom';
import BankDetailsForm from '../../components/common/BankDetailsForm';
import EventsSection from './EventsSection';
import CloseIcon from '@mui/icons-material/Close';
import ConfirmationDialog from '../../components/common/ConfirmationDialog';

export const allowedFileTypes =
  '.csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

const FinSiteDetailsSection = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const studyDetails = useSelector((state) => state.finance.trial);
  const [sites, setSites] = useState([]);
  const [getFianceEnteties] = useGetFianceEntetiesMutation();
  const [assignFinanceSiteStudy, { isLoading: assignLoading }] = useAssignFinanceSiteStudyMutation();
  const [uploadAccountCsv] = useUploadAccountCsvMutation();
  const [uploadBudgetCsv] = useUploadBudgetCsvMutation();
  const [updateFinanceTrialStatus] = useUpdateFinanceTrialStatusMutation();
  const [addFinanceEntity, {isLoading }] = useAddFinanceEntityMutation();
  const [deleteFinanceSiteStudy, {isLoading: deleteSiteLoading }] = useDeleteFinanceSiteStudyMutation();
    // const [openAddSite, setOpenAddSite] = useState(false);
  const [openCreateSite, setOpenCreateSite] = useState(false)
  const [openSiteBudget, setOpenSiteBudget] = useState(false)
  
  const [selectedSite, setSelectedSite] = useState(null);
  const [site, setSite] = useState('');
  const [errors, setErrors] = useState({});
  const [formValues, setFormValues] = useState({
    totalSubjectNumber: '',
    percentOfRevenue: '',
  });
   const [cancelDialog, setCancelDialog] = useState({
     open: false,
     message: "Are you sure you want to cancel ?",
     buttonLabel: "OK",
   });
  
   const [deleteSiteDialog, setDeleteSiteDialog] = useState({
     open: false,
     message: "Are you sure you want to delete site ?",
     buttonLabel: "OK",
   });
  
  const fileInputRef = useRef(null);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  useEffect(() => {
    (async () => {
      const result = await getFianceEnteties({ entityType: 'SITE' }).unwrap();
      if (result) {
        const sites = result.map((site) => {
          return {
            ...site,
            addditionalInfo: JSON.parse(site.addditionalInfo),
          };
        });
        console.log(sites);
        setSites(sites);
      }
    })();
  }, [getFianceEnteties]);

  const handleAssignSite = async () => {
    const validationErrors = validateForm(formValues);
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      await assignFinanceSiteStudy({
        siteEntityId: site,
        studyId: studyDetails?.id,
        totalSubjectNumber: formValues.totalSubjectNumber,
        revenuePercentage: formValues.percentOfRevenue,
      });
      setSite(null);
      setFormValues({
        totalSubjectNumber: '',
        percentOfRevenue: '',
      });
    } else {
    }
  };

  const validateForm = (values) => {
    const errors = {};
    if (!site) {
      errors.site = 'Site is required';
    }
    if (!values.totalSubjectNumber) {
      errors.totalSubjectNumber = 'Number of subject is required';
    }
    if (!values.percentOfRevenue) {
      errors.percentOfRevenue = '% of Revenue is required';
    }
    return errors;
  };

  const handleFileUpload = async (type, acceptedFiles, siteEntityId) => {
    let result;

    const formData = new FormData();
    formData.append('file', acceptedFiles[0]);

    switch (type) {
      case 'ACCOUNTS_CSV':
        result = await uploadAccountCsv({ body: formData });
        break;
      case 'SITE_BUDGET':
        result = await uploadBudgetCsv({ body: formData, protocolNumber: studyDetails?.protocolNumber, siteEntityId });
        break;
      default:
        break;
    }
    if (result.data) {
      dispatch(
        openMessage({
          messageSeverity: 'success',
          message: 'Document Uploaded Successfully!',
        })
      );
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
      fileInputRef.current.blur();
    }
  };

  const handleUpdateTrialStatus = async () => {
    if (studyDetails.clinicalSites.length === 0) {
      dispatch(
        openMessage({
          messageSeverity: 'warning',
          message: 'Please add site to continue',
        })
      );
      return;
    }
    const res =  studyDetails.clinicalSites.every(site => site.events && site.events.length > 0);

    if (!res) {
      dispatch(
        openMessage({
          messageSeverity: 'warning',
          message: 'Please setup budget on all sites to continue',
        })
      );
      return;
  }   

    const result = await updateFinanceTrialStatus({
      protocolNumber: studyDetails?.protocolNumber,
      body: {
        status: 'Ongoing',
      },
    }).unwrap();
    if (result.status === 'Ongoing') {
      dispatch(
        openMessage({
          messageSeverity: 'success',
          message: 'Trial created successfully!',
        })
      );
      navigate('/');
    }
  };
    
  const handleCancel = () => {
    setCancelDialog({ ...cancelDialog, open: false })
    navigate('/')
  }

  const handleCancelDialogClose = () => {
    setCancelDialog({ ...cancelDialog, open: false })
    setDeleteSiteDialog({ ...deleteSiteDialog, open: false })
  }

  const handleDeleteSite = async (site) => {
    await deleteFinanceSiteStudy({ siteEntityId: selectedSite.siteEntityId, studyId: selectedSite.clinicalStudyId })
    setSelectedSite(null)
    setDeleteSiteDialog({ ...deleteSiteDialog, open: false })
  }

  const saveSiteBankDetails = async (data) => {
      try {
        const payload = {
          ...data,
          businessType: 'company',
          externalMasterId: 21,
          entityType: 'SITE',
          email: data.individual.email,
          country: data.bankDetails.country,
          contact: data.individual.phone,
          company: {
            ...data.company,
            address: data.individual.address,
          }
        };
      const res = await addFinanceEntity({
        body: payload,

      }).unwrap();
      if (res) {
        openMessageNotification({
          message: 'Vendor added successfully.',
          type: 'success',
        });
        setOpenCreateSite(false);
      }
      } catch (error) {
        if (error?.data?.message.includes('already exists')) {
          openMessageNotification({
            message: `Vendor with email ${data.individual.email} already exists.`,
            type: 'error',
          });
          return
        }
      console.error(error);
    }
  };

  const openMessageNotification = (message) => {
    dispatch(openMessage({ message: message.message, messageSeverity: message.type }));
  };

  return (
    <>
      <Card sx={{ width: '100%' }}>
        <CardHeader
          title={'Sites/CROs'}
          titleTypographyProps={{ variant: 'h6' }}
          action={
            <>
              <CustomButton
                variant="contained"
                startIcon={<AddIcon />}
                sx={{ mr: 2 }}
                onClick={() => {
                  setOpenCreateSite(true);
                }}
              >
                <Typography variant="subtitle2" fontWeight={600} color={theme.palette.common.white}>
                  Create Site/CRO
                </Typography>
              </CustomButton>
              <CustomButton
                variant="contained"
                startIcon={<CloudUploadOutlinedIcon />}
                component="label"
                onClick={() => {}}
              >
                <Typography variant="subtitle2" fontWeight={600} color={theme.palette.common.white}>
                  Upload Accounts
                </Typography>
                <VisuallyHiddenInput
                  ref={fileInputRef}
                  onChange={(e) => {
                    handleFileUpload('ACCOUNTS_CSV', e.target.files);
                  }}
                  type="file"
                  accept={allowedFileTypes}
                />
              </CustomButton>
              <Typography variant="subtitle1" fontSize={'12px'} color={'black'} textAlign={'right'} mt={1}>
                Note: Upload file with CSV/Excel format.
              </Typography>
            </>
          }
        />
        <CardContent>
          <Grid container spacing={4}>
            <Grid item md={3.5} sx={{ display: 'flex', justifyContent: 'center' }}>
              <FormControl fullWidth error={!!errors.site}>
                <InputLabel>Sites/CROs</InputLabel>
                <Select
                  labelId="sites-select-label"
                  id="sites-simple-select"
                  value={site}
                  label="Sites/CROs"
                  onChange={(e) => {
                    setSite(e.target.value);
                  }}
                  renderValue={(selected) => {
                    const selectedSite = sites.find((s) => s.entityId === selected);
                    return selectedSite ? selectedSite.addditionalInfo.name : 'Select Site/CRO';
                  }}
                >
                  <MenuItem value="" disabled>
                    <em style={{ color: '#aaa', fontStyle: 'normal' }}>
                      {sites.filter(
                        (_site) =>
                          !studyDetails?.clinicalSites?.some((_studySite) => _studySite.siteEntityId === _site.entityId)
                      ).length !== 0
                        ? 'Select Site/CRO'
                        : 'Add new Site/CRO'}
                    </em>
                  </MenuItem>
                  {sites
                    .filter(
                      (_site) =>
                        !studyDetails?.clinicalSites?.some((_studySite) => _studySite.siteEntityId === _site.entityId)
                    )
                    .map((site) => {
                      const siteInfo = site.addditionalInfo;
                      return (
                        <MenuItem key={site.entityId} value={site.entityId}>
                          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                              <Typography variant="body1">{siteInfo.name}</Typography>

                              <Typography
                                variant="body2"
                                fontWeight={600}
                                color={
                                  site.accountStatus === 'Pending'
                                    ? theme.palette.warning.main
                                    : theme.palette.success.main
                                }
                              >
                                {site.accountStatus}
                              </Typography>
                            </Box>
                            <Typography variant="body2" color="textSecondary">
                              {siteInfo.address.line1}, {siteInfo.address.line2}, {siteInfo.address.city},
                              {siteInfo.address.state}, {siteInfo.address.postal_code}, {siteInfo.address.country}
                            </Typography>
                          </Box>
                        </MenuItem>
                      );
                    })}
                </Select>
                <FormHelperText>{errors.site}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item md={3.5}>
              <FormControl fullWidth error={!!errors.totalSubjectNumber}>
                <InputLabel htmlFor="outlined-adornment-amount">Number of Subjects</InputLabel>
                <OutlinedInput
                  value={formValues.totalSubjectNumber}
                  onChange={handleChange}
                  name="totalSubjectNumber"
                  label="Number of Subjects"
                />
                <FormHelperText>{errors.totalSubjectNumber}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item md={3.5}>
              <FormControl fullWidth error={!!errors.percentOfRevenue}>
                <InputLabel htmlFor="outlined-adornment-amount">Revenue</InputLabel>
                <OutlinedInput
                  value={formValues.percentOfRevenue}
                  onChange={handleChange}
                  name="percentOfRevenue"
                  endAdornment={<InputAdornment position="start">%</InputAdornment>}
                  label="Revenue"
                />
                <FormHelperText>{errors.percentOfRevenue}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item md={1.5} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <CustomButton onClick={handleAssignSite} disabled={assignLoading}>
                <Typography variant="subtitle2" fontWeight={600} color={theme.palette.common.white}>
                  Save
                </Typography>
              </CustomButton>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              {studyDetails?.clinicalSites?.length >= 1 ? (
                studyDetails?.clinicalSites?.map((site, index) => {
                  const siteInfo = JSON.parse(site.siteEntity.addditionalInfo);
                  return (
                    <Box
                      key={site.id}
                      sx={{
                        marginTop: index === 0 ? 0 : 3,
                        border: '1px solid #e7e7e7',
                        padding: 3,
                        borderRadius: 1,
                        backgroundColor: '#fbfbfb',
                      }}
                    >
                      <Box sx={{ display: 'flex', marginBottom: 2 }}>
                        <Typography sx={{ flexGrow: 1, fontWeight: 'bold', color: 'grey' }}>
                          {siteInfo?.name}
                        </Typography>

                        <Button
                          variant="outlined"
                          component="label"
                          onClick={() => {
                            // setSelectedSite(site);
                          }}
                          startIcon={<CloudUploadOutlinedIcon />}
                        >
                          Upload Budget
                          <VisuallyHiddenInput
                            ref={fileInputRef}
                            onChange={async (e) => {
                              await handleFileUpload('SITE_BUDGET', e.target.files, site?.siteEntityId);
                            }}
                            type="file"
                            accept={allowedFileTypes}
                          />
                        </Button>
                        <Tooltip
                          title={site.events.length === 0 ? 'Upload budget details.' : ''}
                          disableInteractive
                          disableHoverListener={site.events.length !== 0}
                        >
                          <span>
                            <Button
                              variant="outlined"
                              component="label"
                              onClick={() => {
                                setSelectedSite(site);
                                setOpenSiteBudget(true);
                              }}
                              sx={{ marginX: 2 }}
                              disabled={site.events.length === 0}
                            >
                              View Budget
                            </Button>
                          </span>
                        </Tooltip>
                        <Button
                          variant="outlined"
                          // disabled={ }
                          onClick={() => {
                            setSelectedSite(site);
                            setDeleteSiteDialog({ ...deleteSiteDialog, open: true })
                          }}
                        >
                          <DeleteOutlineOutlinedIcon />
                        </Button>
                      </Box>
                      <Grid container spacing={2}>
                        <Grid item sm={12} md={2}>
                          <Typography sx={{ color: 'grey' }}>{'Site Address:'}</Typography>
                        </Grid>
                        <Grid item sm={12} md={10}>
                          <Typography sx={{ color: 'grey', fontWeight: 'medium' }}>
                            {siteInfo.address.line1}, {siteInfo.address.line2}, {siteInfo.address.city},
                            {siteInfo.address.state}, {siteInfo.address.postal_code}, {siteInfo.address.country}
                          </Typography>
                        </Grid>
                        <Grid item sm={12} md={2}>
                          <Typography sx={{ color: 'grey' }}>{'Contact Information:'}</Typography>
                        </Grid>
                        <Grid item sm={12} md={10}>
                          <Typography sx={{ color: 'grey', fontWeight: 'medium' }}>{siteInfo?.contact}</Typography>
                        </Grid>
                        <Grid item sm={12} md={2}>
                          <Typography sx={{ color: 'grey' }}>{'Contact Email:'}</Typography>
                        </Grid>
                        <Grid item sm={12} md={10}>
                          <Typography sx={{ color: 'grey', fontWeight: 'medium' }}>{siteInfo?.email}</Typography>
                        </Grid>
                        <Grid item sm={12} md={2}>
                          <Typography sx={{ color: 'grey' }}>{'Account Status:'}</Typography>
                        </Grid>
                        <Grid item sm={12} md={10}>
                          <Typography
                            sx={{
                              color:
                                site.siteEntity?.accountStatus === 'Pending'
                                  ? theme.palette.warning.main
                                  : theme.palette.success.main,
                              fontWeight: 'medium',
                            }}
                            fontWeight={600}
                          >
                            {site.siteEntity?.accountStatus}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  );
                })
              ) : (
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      minHeight: 200,
                    }}
                  >
                    <Typography variant="subtitle1" color="initial">
                      No Sites/CROs selected.
                    </Typography>
                    <Typography variant="subtitle2" color="initial" sx={{ fontStyle: 'italic' }}>
                      Note: You can add new site or upload sites information.
                    </Typography>
                  </Box>
                </Box>
              )}
            </Grid>
            {studyDetails?.clinicalSites?.length >= 1 && (
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <CustomButton variant="outlined" onClick={() => setCancelDialog({ ...cancelDialog, open: true })}>
                  Back
                </CustomButton>
                <CustomButton variant="contained" onClick={handleUpdateTrialStatus}>
                  Save & Continue
                </CustomButton>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
      <Dialog
        fullWidth
        maxWidth={'sm'}
        scroll={'paper'}
        open={openCreateSite}
        onClose={() => {
          setOpenCreateSite(false);
        }}
        PaperProps={{
          sx: {
            maxWidth: '800px',
          },
        }}
      >
        <DialogTitle>Add Site/CRO</DialogTitle>
        <DialogContent>
          <BankDetailsForm
            handleClose={() => {
              setOpenCreateSite(false);
            }}
            handleSave={saveSiteBankDetails}
            isLoading={isLoading}
            isCompany={true}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth={'md'}
        scroll={'paper'}
        open={openSiteBudget}
        onClose={() => {
          setOpenSiteBudget(false);
        }}
        PaperProps={{
          sx: {
            maxWidth: 'md',
          },
        }}
      >
        <DialogTitle>Budget Events</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setOpenSiteBudget(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <EventsSection events={selectedSite?.events} />
        </DialogContent>
      </Dialog>
      <ConfirmationDialog
        open={cancelDialog.open}
        buttonLabel={cancelDialog.buttonLabel}
        message={cancelDialog.message}
        handleClose={handleCancelDialogClose}
        handleConfirm={handleCancel}
      />
      <ConfirmationDialog
        open={deleteSiteDialog.open}
        buttonLabel={deleteSiteDialog.buttonLabel}
        message={deleteSiteDialog.message}
        handleClose={handleCancelDialogClose}
        handleConfirm={handleDeleteSite}
      />
    </>
  );
};

export default FinSiteDetailsSection;
