import React from 'react'
import CustomCard from "../../components/@extended/CustomCard";
import {
  Box,
  CardContent,
  Divider,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { useSelector } from "react-redux";
import Label from "../../components/common/Label";


const FinanceTrialDetailCard = () => {
  const theme = useTheme();
    const trialDetail = useSelector((state) => state.finance.trial);
    
  return (
    <CustomCard title="Study Summary">
      <CardContent>
        <Grid container>
          <Grid item md={12} sm={12}>
            <Box
              sx={{
                display: 'flex',
                pb: 1.5,
                paddingRight: 2,
                width: '100%',
              }}
            >
              <Label >Trial Title :</Label>
              <Typography
                variant="subtitle2"
                color="initial"
                sx={{
                    maxWidth: '100%', 
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
              >
                {trialDetail?.title}
              </Typography>
            </Box>
            <Divider />
          </Grid>
          <Grid item md={6} sm={12}>
            <Box sx={{ display: 'flex', py: 1.5 }}>
              <Label>Protocol Number :</Label>
              <Typography variant="subtitle2" color="initial">
                {trialDetail?.protocolNumber}
              </Typography>
            </Box>
            <Divider />
          </Grid>
          <Grid item md={6} sm={12}>
            <Box
              sx={{
                display: 'flex',
                py: 1.5,
                [theme.breakpoints.down('md')]: {
                  pt: 1.5,
                },
              }}
            >
              <Label>Number of Subjects :</Label>
              <Typography variant="subtitle2" color="initial">
                {trialDetail?.totalSubjectNumber}
              </Typography>
            </Box>
            <Divider />
          </Grid>
          <Grid item md={6} sm={12}>
            <Box sx={{ display: 'flex', py: 1.5 }}>
              <Label>Number of Sites :</Label>
              <Typography variant="subtitle2" color="initial">
                {trialDetail?.clinicalSites?.length}
              </Typography>
            </Box>
          </Grid>
          <Grid item md={6} sm={12}>
            <Box sx={{ display: 'flex', pt: 1.5, alignItems: 'center' }}>
              <Label>Trial Payment Account Status :</Label>
              <Typography variant="subtitle2" color="initial">
                --
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </CustomCard>
  );
};

export default FinanceTrialDetailCard