import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { CustomTableHead, CustomTableHeadCell } from '../../../components/@extended/CustomTable';
import { Edit, Check, Delete } from '@mui/icons-material';
import CustomButton from '../../../components/@extended/CustomButton';
import moment from 'moment';
import { useGenerateFianceInvoiceMutation } from '../../../store/slices/apiSlice';

const GenerateInvoice = ({handleClose}) => {
  const [generateFianceInvoice, { isLoading }] = useGenerateFianceInvoiceMutation();

  const [columns] = useState([
    { key: 'srno', label: 'Sr. No.', maxWidth: 80, align: 'center' },
    { key: 'description', label: 'Service Description', minWidth: 180, align: 'center' },
    { key: 'amount', label: 'Amount', minWidth: 80, align: 'center' },
    { key: 'quantity', label: 'UoQ', minWidth: 80, align: 'center' },
    { key: 'total', label: 'Total', minWidth: 80, align: 'center' },
    { key: 'actions', label: '', minWidth: 140, align: 'center' },
  ]);

  const theme = useTheme();

  const selectedSite = useSelector((state) => state.finance?.currentSite);
  const sponsor = useSelector((state) => state.userDetails?.user);

  const siteInfo = useMemo(() => {
    if (!selectedSite?.siteEntity?.addditionalInfo) return;
    return JSON.parse(selectedSite?.siteEntity?.addditionalInfo);
  }, [selectedSite]);

  const [invoiceItems, setInvoiceItems] = useState([
    { srno: 1, description: 'Service 1', amount: 100, quantity: 2, total: 200 },
    { srno: 2, description: 'Service 2', amount: 150, quantity: 1, total: 150 },
  ]);
  const [editIndex, setEditIndex] = useState(null);
  const [formData, setFormData] = useState({ description: '', amount: '', quantity: '', total: '' });

  const handleEdit = (index) => {
    setEditIndex(index);
    setFormData({ ...invoiceItems[index] });
  };

  const handleChange = (e, key) => {
    const value = e.target.value;
    let updatedData = { ...formData, [key]: value };

    if (key === 'amount' || key === 'quantity') {
      updatedData.total = updatedData.amount * updatedData.quantity;
    }

    setFormData(updatedData);
  };

  const handleSave = () => {
    const updatedItems = invoiceItems.map((item, index) => (index === editIndex ? { ...formData } : item));
    setInvoiceItems(updatedItems);
    setEditIndex(null);
  };

  const handleRemoveRow = (index) => {
    setInvoiceItems((prev) => prev.filter((_, i) => i !== index));
  };

  const handleAddRow = () => {
    setInvoiceItems([
      ...invoiceItems,
      { srno: invoiceItems.length + 1, description: '', amount: '', quantity: '', total: '' },
    ]);
    setEditIndex(invoiceItems.length);
    setFormData({ srno: invoiceItems.length + 1, description: '', amount: '', quantity: '', total: '' });
  };

  const { subtotal, taxes, stripeCharges, transactionHandlingCost, totalAmountDue } = useMemo(() => {
    const subtotal = invoiceItems.reduce((acc, item) => acc + Number(item.total), 0);
    const taxes = subtotal * 0.10;
    const stripeCharges = subtotal * 0.0025;
    const transactionHandlingCost = 1;
    const totalAmountDue = subtotal + taxes + stripeCharges + transactionHandlingCost;

    return { subtotal, taxes, stripeCharges, transactionHandlingCost, totalAmountDue };
  }, [invoiceItems]);

  const invoiceNumber = useMemo(() => {
    const siteCode = 'BQW';
    const datePart = moment().format('DDmm');
    const randomPart = Math.floor(1000 + Math.random() * 9000); // 4-digit random number
    return `${siteCode}-${datePart}-${randomPart}`;
  }, []);

  const handleGenerateInvoice = async () => {
    const body = {
      invoiceNumber: invoiceNumber,
      invoiceDate: moment().format('YYYY-MM-DD'),
      dueDate: moment().add(3, 'days').format('YYYY-MM-DD'),
      recipientEntityId: sponsor?.financeDetail?.entityId,
      issuerEntityId: selectedSite.siteEntityId,
      amount: totalAmountDue,
      items: invoiceItems.map((i) => {
        return {
          description: i.description,
          amount: Number(i.amount),
          quantity: Number(i.quantity),
        };
      }),
    };

    const res = await generateFianceInvoice({ body });
    if (res.data) {
      handleClose();
    }
  };

  return (
    <Card elevation={10}>
      <CardHeader
        title="Invoice"
        titleTypographyProps={{ variant: 'h4', fontWeight: 600 }}
        sx={{ fontWeight: 600, textAlign: 'center' }}
      />
      <CardContent sx={{ px: 5 }}>
        <Grid container spacing={3}>
          <Grid item xs={5} rowGap={2}>
            <Typography variant="h6" color="initial" sx={{ fontWeight: 600 }}>
              Issuer Information
            </Typography>
            <Typography variant="subtitle1" color="initial" sx={{ fontWeight: 600 }}>
              Name: &nbsp; <span style={{ fontWeight: 500 }}>{siteInfo?.name}</span>
            </Typography>
            <Typography variant="subtitle1" color="initial" sx={{ fontWeight: 600 }}>
              Address:&nbsp;{' '}
              <span
                style={{ fontWeight: 500 }}
              >{`${siteInfo?.address.line1}, ${siteInfo?.address.line2}, ${siteInfo?.address.city}`}</span>
            </Typography>
            <Typography variant="subtitle1" color="initial" sx={{ fontWeight: 600 }}>
              Phone: &nbsp;<span style={{ fontWeight: 500 }}>{siteInfo?.contact}</span>
            </Typography>
            <Typography variant="subtitle1" color="initial" sx={{ fontWeight: 600 }}>
              Email: &nbsp;<span style={{ fontWeight: 500 }}>{siteInfo?.email}</span>
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ pt: 0 }}></Grid>
          <Grid item xs={5}>
            <Typography variant="h6" color="initial" sx={{ fontWeight: 600 }}>
              Recipient Information
            </Typography>
            <Typography variant="subtitle1" color="initial" sx={{ fontWeight: 600 }}>
              Name: &nbsp; <span style={{ fontWeight: 500 }}>{sponsor.orgnizationName}</span>
            </Typography>
            <Typography variant="subtitle1" color="initial" sx={{ fontWeight: 600 }}>
              Address:&nbsp; <span style={{ fontWeight: 500 }}>{sponsor.address}</span>
            </Typography>
            <Typography variant="subtitle1" color="initial" sx={{ fontWeight: 600 }}>
              Phone: &nbsp;<span style={{ fontWeight: 500 }}>{sponsor.contactNumber}</span>
            </Typography>
            <Typography variant="subtitle1" color="initial" sx={{ fontWeight: 600 }}>
              Email: &nbsp;<span style={{ fontWeight: 500 }}>{sponsor.contactEmail}</span>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {/* <Box sx={{ height: 15, width: '100%' }}></Box> */}
          <Grid item xs={7} sx={{ pt: 0 }}></Grid>
          <Grid item xs={4}>
            <Typography variant="h6" color="initial" sx={{ fontWeight: 600 }}>
              Invoice Details
            </Typography>
            <Typography variant="subtitle1" color="initial" sx={{ fontWeight: 600 }}>
              Invoice Number:&nbsp; <span style={{ fontWeight: 500 }}>{invoiceNumber}</span>
            </Typography>
            <Typography variant="subtitle1" color="initial" sx={{ fontWeight: 600 }}>
              Date:&nbsp; <span style={{ fontWeight: 500 }}>{moment().format('dd MMM yyyy')}</span>
            </Typography>
          </Grid>
          {/* <Box sx={{ height: 15, width: '100%' }}></Box>*/}
          <Grid item xs={12}>
            <Typography variant="h6" color="initial" sx={{ fontWeight: 600, mb: 1 }}>
              Description of Services
            </Typography>
            <TableContainer>
              <Table aria-label="spanning table">
                <CustomTableHead>
                  <TableRow>
                    {columns &&
                      columns.map((col) => (
                        <CustomTableHeadCell
                          key={col.key}
                          align={col.align}
                          sx={{
                            minWidth: col?.minWidth,
                            maxWidth: col?.maxWidth,
                            // fontSize: 15,
                          }}
                        >
                          {col.label}
                        </CustomTableHeadCell>
                      ))}
                  </TableRow>
                </CustomTableHead>
                <TableBody>
                  {invoiceItems.map((row, index) => (
                    <TableRow key={index}>
                      {columns.map((column) => {
                        if (column.key === 'actions') {
                          return (
                            <TableCell align="center" key={column.key} sx={{ py: 1 }}>
                              {editIndex === index ? (
                                <IconButton color="success" onClick={handleSave} disabled={isLoading}>
                                  <Check />
                                </IconButton>
                              ) : (
                                <IconButton
                                  disableRipple
                                  color={theme.palette.grey[100]}
                                  onClick={() => handleEdit(index)}
                                  disabled={isLoading}
                                >
                                  <Edit />
                                </IconButton>
                              )}
                              <IconButton
                                disableRipple
                                color={theme.palette.error.light}
                                onClick={() => handleRemoveRow(index)}
                                disabled={invoiceItems.length === 1 || isLoading}
                              >
                                <Delete />
                              </IconButton>
                            </TableCell>
                          );
                        }
                        if (column.key === 'srno') {
                          return (
                            <TableCell align="center" key={column.key} sx={{ py: 1 }}>
                              {index + 1}
                            </TableCell>
                          );
                        }
                        return (
                          <TableCell key={column.key} align={column.align} sx={{ py: 1 }}>
                            {editIndex === index ? (
                              <TextField
                                variant="standard"
                                value={formData[column.key]}
                                onChange={(e) => handleChange(e, column.key)}
                                size="small"
                                type={column.key === 'amount' || column.key === 'quantity' ? 'number' : 'text'}
                              />
                            ) : (
                              <>
                                {column.key === 'amount' || column.key === 'total' ? '$' : ''} {row[column.key]}
                              </>
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
                  <TableRow sx={{ backgroundColor: theme.palette.grey[50] }}>
                    <TableCell colSpan={5} align="center" sx={{ py: 1 }}></TableCell>
                    <TableCell align="center" sx={{ py: 1 }}>
                      <Button variant="outlined" color="primary" onClick={handleAddRow} disabled={isLoading}>
                        Add Row
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Box sx={{ height: 15, width: '100%' }}></Box>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Box>
              <Box sx={{ width: '100%', display: 'flex' }}>
                <Typography variant="subtitle1" color="initial" sx={{ fontWeight: 600, width: '70%' }}>
                  Subtotal:
                </Typography>
                <Typography variant="subtitle1" color="initial" sx={{ fontWeight: 600, width: '30%' }}>
                  <span style={{ fontWeight: 500 }}>$ {subtotal.toFixed(2)}</span>
                </Typography>
              </Box>

              <Box sx={{ width: '100%', display: 'flex' }}>
                <Typography variant="subtitle1" color="initial" sx={{ fontWeight: 600, width: '70%' }}>
                  Taxes :
                </Typography>
                <Typography variant="subtitle1" color="initial" sx={{ fontWeight: 600, width: '30%' }}>
                  <span style={{ fontWeight: 500 }}>$ {taxes.toFixed(2)}</span>
                </Typography>
              </Box>

              <Box sx={{ width: '100%', display: 'flex' }}>
                <Typography variant="subtitle1" color="initial" sx={{ fontWeight: 600, width: '70%' }}>
                  Stripe Charges :
                </Typography>
                <Typography variant="subtitle1" color="initial" sx={{ fontWeight: 600, width: '30%' }}>
                  <span style={{ fontWeight: 500 }}>$ {stripeCharges.toFixed(2)}</span>
                </Typography>
              </Box>

              <Box sx={{ width: '100%', display: 'flex' }}>
                <Typography variant="subtitle1" color="initial" sx={{ fontWeight: 600, width: '70%' }}>
                  Transaction Handling cost :
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="initial"
                  sx={{ fontWeight: 600, width: '30%', height: '100%', display: 'flex', alignItems: 'flex-end' }}
                >
                  <span style={{ fontWeight: 500 }}>$ {transactionHandlingCost.toFixed(2)}</span>
                </Typography>
              </Box>
              <Divider />
              <Box sx={{ width: '100%', display: 'flex' }}>
                <Typography variant="subtitle1" color="initial" sx={{ fontWeight: 600, width: '70%' }}>
                  Total Amount Due :
                </Typography>
                <Typography variant="subtitle1" color="initial" sx={{ fontWeight: 600, width: '30%' }}>
                  <span style={{ fontWeight: 500 }}> {`$ ${totalAmountDue.toFixed(2)}`}</span>
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
           {/* <Grid item xs={6}>
            <Typography variant="h6" color="initial" sx={{ fontWeight: 600 }}>
              Payment Terms
            </Typography>
            <Typography variant="subtitle1" color="initial">
             {`Payment is due within x days of the invoice date. Please remit payment to ${sponsor.orgName}, Account
                Number: ${sponsor.accountDetails.routingNumber}, Bank: ${sponsor.addressLine1}, SWIFT: }.`} 
            </Typography>
          </Grid> */}
          <Box sx={{ height: 150, width: '100%' }}></Box>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                      <CustomButton variant="outlined" size="small" sx={{ mr: 2 }} disabled={isLoading} onClick={ handleClose}>
              Cancel
            </CustomButton>
            <CustomButton variant="contained" size="small" disabled={isLoading || invoiceItems.some((i)=> !i.amount || i.quantity === 0 )} onClick={handleGenerateInvoice}>
              Create Invoice
            </CustomButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default GenerateInvoice;
