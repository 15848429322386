import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CustomTableHeadCell } from '../../../../components/@extended/CustomTable';
import { selectStudySiteDetails } from '../../../../store/slices/financeSlice';
import CustomButton from '../../../../components/@extended/CustomButton';
import RecipientInformation from './RecipientInformation';

const ManageInvoice = ({ selectedSite, setSelectedSite, closeInvoice }) => {
  const theme = useTheme();
  const { sponsorId, studyId } = useParams();
  const templates = useSelector((state) => state.finance.invoiceTemplates);
  const sponsor = useSelector((state) =>
    state.finance.sponsors.find((sponsor) => String(sponsor.id) === String(sponsorId))
  );
  const [editTemplate, setEditTemplate] = useState(false);
  const [template, setTemplate] = useState(1);
  const [templateData, setTemplateData] = useState(null);

  const [columns, setColumns] = useState([
    {
      key: 'srno',
      label: 'Sr. No.',
      maxWidth: 50,
      align: 'center',
    },
    {
      key: 'serviceDescription',
      label: 'Service Description',
      minWidth: 100,
      align: 'center',
    },
    {
      key: 'Date',
      label: 'Date',
      minWidth: 100,
      align: 'center',
    },
    {
      key: 'Rate',
      label: 'Rate',
      minWidth: 100,
      align: 'center',
    },
    {
      key: 'UoQ',
      label: 'UoQ',
      minWidth: 100,
      align: 'center',
    },
    {
      key: 'Total',
      label: 'Total ',
      minWidth: 100,
      align: 'center',
    },
  ]);

  const [rows, setRows] = useState([
    {
      srno: 1,
      serviceDescription: 'Informed Consent',
      Date: '01/06/2024',
      Rate: '$100',
      UoQ: 1,
      Total: '$100',
    },
    {
      srno: 2,
      serviceDescription: 'COPD Assessment Test',
      Date: '01/06/2024',
      Rate: '$100',
      UoQ: 3,
      Total: '$300',
    },
    {
      srno: 3,
      serviceDescription: '4-METER Gait Speed',
      Date: '01/06/2024',
      Rate: '$200',
      UoQ: 2,
      Total: '$400',
    },
    {
      srno: 4,
      serviceDescription: 'Lab Handling',
      Date: '01/06/2024',
      Rate: '$50',
      UoQ: 1,
      Total: '$50',
    },
  ]);

  const handleEditTemplate = () => {
    if (!editTemplate) {
      setEditTemplate(true);
    } else {
      setEditTemplate(false);
    }
  };

  useEffect(() => {
    if (template && templates) {
      const temp = templates.find((_temp) => _temp.id === template);
      setTemplateData(temp);
    }
  }, [template, templates]);

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          flex: 'grow',
          display: 'flex',
          height: 70,
        }}
      >
        <Button
          style={{ position: 'absolute', top: 12 }}
          onClick={() => closeInvoice()}
          // startIcon={<RightArrow leftArrow />}
        >
          <Typography variant="subtitle1" sx={{ textTransform: 'none' }} color={theme.palette.primary.light}>
            <u>Back</u>
          </Typography>
        </Button>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: 600 }} ml={'auto'} mr={'auto'}>
            Site Name : <span style={{ fontWeight: 500 }}> {selectedSite?.orgName}</span>
          </Typography>
        </Box>
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={3}></Grid>
        <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center' }}>
          <FormControl fullWidth>
            <InputLabel>Invoice Templates</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={template}
              label="Invoice Templates"
              onChange={(e) => {
                setTemplate(e.target.value);
              }}
            >
              {templates &&
                templates.map((_temp) => (
                  <MenuItem key={_temp.label} value={_temp.id}>
                    {_temp.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
          <CustomButton onClick={() => {}} variant="outlined" sx={{ mr: 2 }} disabled={editTemplate}>
            Use
          </CustomButton>
          <CustomButton variant="outlined" onClick={handleEditTemplate}>
            {!editTemplate ? 'Edit Template' : 'Save'}
          </CustomButton>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={12}>
          <Card elevation={10}>
            <CardHeader
              title="Invoice"
              titleTypographyProps={{ variant: 'h4', fontWeight: 600 }}
              sx={{ fontWeight: 600, textAlign: 'center' }}
            />
            <CardContent sx={{ px: 5 }}>
              <Grid container spacing={3}>
                <Grid item xs={3.5}>
                  <RecipientInformation
                    editTemplate={editTemplate}
                    type={'recepientInformation'}
                    templateData={templateData}
                  />
                </Grid>
                <Grid item xs={5}></Grid>
                <Grid item xs={3.5}>
                  <RecipientInformation
                    editTemplate={editTemplate}
                    type={'sponsorInformation'}
                    templateData={templateData}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={6}>
                  <RecipientInformation
                    editTemplate={editTemplate}
                    type={'invoiceDetails'}
                    templateData={templateData}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" color="initial" sx={{ fontWeight: 600, mb: 1 }}>
                    Description of Services
                  </Typography>
                  <TableContainer>
                    <Table sx={{}} aria-label="spanning table">
                      <TableHead sx={{ backgroundColor: '#f9f9f9' }}>
                        <TableRow>
                          {columns &&
                            columns.map((col) => (
                              <CustomTableHeadCell
                                key={col.key}
                                align={col.align}
                                sx={{
                                  minWidth: col?.minWidth,
                                  maxWidth: col?.maxWidth,
                                  fontSize: 16,
                                }}
                              >
                                {col.label}
                              </CustomTableHeadCell>
                            ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row, index) => {
                          return (
                            <TableRow key={index}>
                              {columns.map((column, _index) => {
                                const value = row[column.key];
                                return (
                                  <TableCell key={`${column.key}${index}`} align={column.align} sx={{ fontSize: 16 }}>
                                    {value}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                {/* <Box sx={{ height: 15, width: '100%' }}></Box> */}
                <Grid item xs={8} sx={{ display: 'flex' }}></Grid>
                <Grid item xs={4}>
                  <Box sx={{ width: '100%', display: 'flex' }}>
                    <Typography variant="subtitle1" color="initial" sx={{ fontWeight: 600, width: '70%' }}>
                      Subtotal:
                    </Typography>
                    <Typography variant="subtitle1" color="initial" sx={{ fontWeight: 600, width: '30%' }}>
                      <span style={{ fontWeight: 500 }}>$850</span>
                    </Typography>
                  </Box>

                  <Box sx={{ width: '100%', display: 'flex' }}>
                    <Typography variant="subtitle1" color="initial" sx={{ fontWeight: 600, width: '70%' }}>
                      Taxes:
                    </Typography>
                    <Typography variant="subtitle1" color="initial" sx={{ fontWeight: 600, width: '30%' }}>
                      <span style={{ fontWeight: 500 }}>$850</span>
                    </Typography>
                  </Box>

                  <Box sx={{ width: '100%', display: 'flex' }}>
                    <Typography variant="subtitle1" color="initial" sx={{ fontWeight: 600, width: '70%' }}>
                      Stripe Charges:
                    </Typography>
                    <Typography variant="subtitle1" color="initial" sx={{ fontWeight: 600, width: '30%' }}>
                      <span style={{ fontWeight: 500 }}>$850</span>
                    </Typography>
                  </Box>

                  <Box sx={{ width: '100%', display: 'flex' }}>
                    <Typography variant="subtitle1" color="initial" sx={{ fontWeight: 600, width: '70%' }}>
                      Transaction Handling cost:
                    </Typography>
                    <Typography variant="subtitle1" color="initial" sx={{ fontWeight: 600, width: '30%' }}>
                      <span style={{ fontWeight: 500 }}>$850</span>
                    </Typography>
                  </Box>
                  <Box sx={{ width: '100%', display: 'flex' }}>
                    <Typography variant="subtitle1" color="initial" sx={{ fontWeight: 600, width: '70%' }}>
                      Total Amount Due:
                    </Typography>
                    <Typography variant="subtitle1" color="initial" sx={{ fontWeight: 600, width: '30%' }}>
                      <span style={{ fontWeight: 500 }}>$850</span>
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6" color="initial" sx={{ fontWeight: 600 }}>
                    Payment Terms
                  </Typography>
                  <Typography variant="subtitle1" color="initial">
                    {`Payment is due within x days of the invoice date. Please remit payment to ${sponsor.orgName}, Account
                Number: ${sponsor.accountDetails.routingNumber}, Bank: ${sponsor.addressLine1}, SWIFT: .`}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ pl: 10 }}>
                    <Typography variant="h6" color="initial" sx={{ fontWeight: 600 }}>
                      Authorization
                    </Typography>
                    <Typography variant="subtitle1" color="initial" sx={{ fontWeight: 600 }}>
                      Authorized Signature:&nbsp; <span style={{ fontWeight: 500 }}>__________________</span>
                    </Typography>
                  </Box>
                </Grid>
                <Box sx={{ height: 250, width: '100%' }}></Box>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default ManageInvoice;
