import React, { useState } from 'react'
import { useDownloadCsvTemplateMutation } from '../../store/slices/apiSlice';
import { openMessage } from '../../store/slices/showMessageSlice';
import { useDispatch } from 'react-redux';
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Typography,
  } from '@mui/material';
import CustomButton from '../@extended/CustomButton';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

const DownloadTemplate = () => {
    const dispatch = useDispatch()
    const [downloadCsvTemplate, { data: downloadedFile }] = useDownloadCsvTemplateMutation();
    const [templateDialog, setTemplateDialog] = useState(false);
    const [value, setValue] = useState('');
    const [error, setError] = useState('');
  
    const onCloseModal = () => {
      setError('');
      setValue('');
      setTemplateDialog(false);
    };
  
    function handleChange(value) {
      setValue(value);
      setError('');
    }

const handleDownloadCsv = async () => {
    if (!value) {
      setError('Please select csv template type');
      return;
    }
    try {
      const response = await downloadCsvTemplate(value).unwrap();
      // Ensure response is handled as a Blob
      const blob = new Blob([response], { type: 'text/csv' });

      // Extract filename from content-disposition header
      let fileName = 'download.csv'; // Default name

      switch (value) {
        case 'ACCOUNT_ONBOARDING':
          fileName = 'account-onboarding.csv';
          break;
        case 'EVENTS_PAYOUTS':
          fileName = 'budget-planning.csv';
          break;
        case 'EVENTS_PAYMENT':
          fileName = 'event-payments.csv';
          break;
        default:
          fileName = 'download.csv';
          break;
      }

      // Create a download link
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();

      // Cleanup
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      setTemplateDialog(false);
      dispatch(
        openMessage({
          messageSeverity: 'success',
          message: 'Template csv downloaded successfully!',
        })
      );
    } catch (error) {
      dispatch(
        openMessage({
          messageSeverity: 'error',
          message: 'Download Failed!',
        })
      );
    }
  };

  return (
      <>
          <CustomButton
         
          onClick={() => setTemplateDialog(true)}
          variant="contained"
          startIcon={<FileDownloadOutlinedIcon />}
        >
          Templates
        </CustomButton>
      <Dialog
        open={templateDialog}
        PaperProps={{ sx: { minHeight: 'fit-content' } }}
        scroll={'paper'}
        maxWidth={'xs'}
        onClose={onCloseModal}
        fullWidth={true}
      >
        <DialogTitle>Download template</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <FormControl sx={{ minWidth: '100%', height: '70px', mt: 1 }}>
              <InputLabel>Template Type</InputLabel>
              <Select
                label="Template Type"
                value={value}
                onChange={(e) => handleChange(e.target.value)}
                placeholder="Document type"
              >
                <MenuItem value={'ACCOUNT_ONBOARDING'}>Onboard Accounts</MenuItem>
                <MenuItem value={'EVENTS_PAYOUTS'}>Budget Planning</MenuItem>
                <MenuItem value={'EVENTS_PAYMENT'}>Trial Payment</MenuItem>
              </Select>
              {error && (
                <Typography variant="body2" color="error" sx={{ textAlign: 'left', pt: 0.5, mb: 1 }}>
                  {error}
                </Typography>
              )}
            </FormControl>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', pt: error ? 4 : 2 }}>
            <CustomButton variant={'outlined'} size="small" sx={{ mr: 2 }} onClick={onCloseModal}>
              Cancel
            </CustomButton>
            <CustomButton variant={'contained'} size="small" onClick={handleDownloadCsv}>
              Download
            </CustomButton>
          </Box>
        </DialogContent>
      </Dialog>
      </>
  )
}

export default DownloadTemplate