import React from 'react';
import TextFieldContainer from '../../components/inputContainers/TextFieldContainer';
import { Card, CardContent, CardHeader, Grid, useTheme, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import moment from 'moment';
import dayjs from 'dayjs';
import { getIn, useFormik } from 'formik';
import * as Yup from 'yup';
import CustomButton from '../../components/@extended/CustomButton';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { openMessage } from '../../store/slices/showMessageSlice';
import { useCreateFinanceTrialMutation } from '../../store/slices/apiSlice';
import { setFinanceTrial } from '../../store/slices/financeSlice';

const FinanceTrialDetailsForm = () => {
  const theme = useTheme();
  let { trialId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sponsorDetails = useSelector((state) => state.userDetails.user);
  const trialDetails = useSelector((state) => state.finance?.trial);
  const [createFinanceTrial,{isLoading}] = useCreateFinanceTrialMutation();
  const trialDetailsValidation = Yup.object().shape({
    protocolNumber: Yup.string()
      .required('Protocol Number is required')
      .trim('The Protocol Number cannot include leading and trailing spaces')
      .strict(true)
      .min(2, 'Minimum 2 characters are required')
      .max(50, 'Maximum 50 characters are allowed'),
    protocolVersion: Yup.string()
      .required('Protocol Version is required')
      .trim('The Protocol Version cannot include leading and trailing spaces')
      .strict(true)
      .min(2, 'Minimum 2 characters are required')
      .max(50, 'Maximum 50 characters are allowed'),
    protocolVersionDate: Yup.date().required('Protocol Version Date is required'),
    title: Yup.string()
      .required('Trial Title is Required')
      .trim('The Trial Title cannot include leading and trailing spaces')
      .strict(true)
      .min(2, 'Minimum 2 characters are required'),
    totalSubjectNumber: Yup.number()
      .required('Subject number is Required')
      .min(0, 'Number of subject cannot be negative')
      .max(1000000, 'Total subjects cannot be more than 1000000'),
  });

  let trialDetailsInitialValues = {
    protocolNumber: trialDetails?.protocolNumber || '',
    protocolVersion: trialDetails?.protocolVersion || '',
    protocolVersionDate: trialDetails?.protocolVersionDate
      ? moment(trialDetails.protocolVersionDate).format('MM-DD-YYYY')
      : moment().format('MM-DD-YYYY'),
    title: trialDetails?.title || '',
    totalSubjectNumber: trialDetails?.totalSubjectNumber || '',
  };

  const formik = useFormik({
    validateOnMount: true,
    initialValues: trialDetailsInitialValues,
    validationSchema: trialDetailsValidation,
    enableReinitialize: true,
    onSubmit: (values) => {
      return values;
    },
  });

  const openMessageNotification = (message) => {
    dispatch(openMessage({ message: message.message, messageSeverity: message.type }));
  };

  const handleDateChange = (date) => {
    const formatedDate = dayjs(date).format('MM/DD/YYYY');
    formik.setFieldValue('protocolVersionDate', formatedDate, true);
  };

  const handleCreateTrial = async () => {
    try {
      formik.handleSubmit();

      if (formik.isValid) {
        const payload = {
          ...formik.values,
          ownerEntityId: sponsorDetails.financeDetail?.entityId,
          trialId: '1',
        };
        const result = await createFinanceTrial(payload);
        if (result.data) {
          openMessageNotification({
            message: 'Submitted Successfully',
            type: 'success',
          });
            dispatch(setFinanceTrial(result.data))
          navigate(`/createFinanceTrial/${result.data.id}`)
        } else {
            console.error(result.error.data);
            openMessageNotification({
              message: result.error.data.message,
              type: 'error',
            });
        }
      } else {
        openMessageNotification({
          message: 'Check if all fields are valid!',
          type: 'warning',
        });
      }
    } catch (error) {
      console.error(error);
      openMessageNotification({
        message: error?.message,
        type: 'error',
      });
    }
  };
  return (
    <Card sx={{ width: '100%' }}>
      <CardHeader title={'Study Details'} titleTypographyProps={{ variant: 'h6' }} />
      <CardContent>
        <Grid container pr={2}>
          <Grid item sm={12} md={5.5} sx={{ pb: 2 }}>
            <TextFieldContainer
              name={'protocolNumber'}
              placeholder={'Enter Protocol Number'}
              label={'Protocol Number'}
              formik={formik}
            />
          </Grid>
          <Grid item sm={0} md={1}></Grid>
          <Grid item sm={12} md={5.5} sx={{ pb: 2 }}>
            <TextFieldContainer
              name={'protocolVersion'}
              placeholder={'Enter Protocol Version'}
              label={'Protocol Version'}
              formik={formik}
            />
          </Grid>
          <Grid item sm={12} md={5.5} sx={{ py: 2 }}>
            <Typography variant="subtitle1" color={theme.palette.text.secondary} sx={{ pb: 0.5 }}>
              Protocol Version Date
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={dayjs(
                  new Date(
                    moment(
                      getIn(formik.values, 'protocolVersionDate') && getIn(formik.values, 'protocolVersionDate'),
                      'MM/DD/YYYY'
                    )
                  )
                )}
                error={getIn(formik.touched, 'protocolVersionDate') && getIn(formik.error, 'protocolVersionDate')}
                slotProps={{
                  textField: {
                    error:
                      getIn(formik.touched, 'protocolVersionDate') &&
                      getIn(formik.error, 'protocolVersionDate') &&
                      true,
                    helperText:
                      getIn(formik.touched, 'protocolVersionDate') && getIn(formik.error, 'protocolVersionDate'),
                    name: 'protocolVersionDate',
                    fullWidth: true,
                    size: 'small',
                  },
                }}
                onChange={handleDateChange}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item sm={0} md={1}></Grid>
          <Grid item sm={12} md={5.5} sx={{ py: 2 }}>
            <TextFieldContainer
              name={'title'}
              placeholder={'Enter Trial Title'}
              label={'Trial Title'}
              formik={formik}
            />
          </Grid>
          {/* <Grid item sm={0} md={1}></Grid> */}
          <Grid item sm={12} md={5.5} sx={{ py: 1 }}>
            <TextFieldContainer
              name={'totalSubjectNumber'}
              placeholder={'Enter Number of Subjects'}
              label={'Number of Subjects'}
              formik={formik}
              type={'number'}
            />
          </Grid>
          {!trialId && (
            <Grid
              item
              sm={12}
              md={12}
              sx={{
                py: 2,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <CustomButton variant="outlined" color="primary" size="large" onClick={handleCreateTrial} sx={{ mr: 3 }}>
                Clear
              </CustomButton>
              <CustomButton
                variant="contained"
                color="primary"
                size="large"
                onClick={handleCreateTrial}
                sx={{ color: 'white' }}
                disabled={isLoading}
              >
                Create
              </CustomButton>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default FinanceTrialDetailsForm;
