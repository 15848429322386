import { Button, Grid, Typography, Box } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import RightArrow from '../../components/icons/RightArrow';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useGetFianceTrialByIdQuery } from '../../store/slices/apiSlice';
import FinanceTrialDetailCard from './FinanceTrialDetailCard';
import { setFinanceTrial } from '../../store/slices/financeSlice';
import FinanceSiteDetailsCard from './FinanceSiteDetailsCard';

const FinanceTrialDetails = () => {
  const navigate = useNavigate();
  let { trialId } = useParams();
  const dispatch = useDispatch();

  const { data } = useGetFianceTrialByIdQuery(trialId, { skip: !trialId, refetchOnMountOrArgChange:true });

  const handleBack = () => {
    dispatch(setFinanceTrial(null))
    navigate(-1)
  }
  return (
    <>
      <Grid container rowGap={2}>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button type="text" onClick={handleBack} startIcon={<RightArrow leftArrow />}>
              <Typography variant="subtitle1" sx={{ textTransform: 'none' }}>
                Back to Home
              </Typography>
            </Button>
            {/* {userRole === 'site_monitor' ? (
              <AuditExportButton protocolNumber={trialDetails?.trialData?.protocolNumber} siteName={trialSites?.[0]?.site?.orgname}/>
            ) : userRole === 'sponsor' ? (
              <AuditExportButton protocolNumber={trialDetails?.trialData?.protocolNumber} />
            ) : (
              ''
            )} */}
          </Box>
        </Grid>
        <Grid container direction={'row'} columnSpacing={3} rowSpacing={2}>
          <Grid item sm={12} md={12}>
            <FinanceTrialDetailCard />
          </Grid>
          <Grid item sm={12} md={12}>
            <FinanceSiteDetailsCard />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default FinanceTrialDetails;
