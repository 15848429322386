import React, { useEffect, useState } from 'react'
import { Field, Form, Formik } from 'formik';
import {
    Box,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Typography,
    CircularProgress,
  } from '@mui/material';
import CustomButton from '../@extended/CustomButton';
import Phone, {phoneValidationSchema} from './Phone';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const validationSchema = Yup.object().shape({
  individual: Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    phone: phoneValidationSchema.concat(Yup.string().required('Contact Number is required')),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    dob: Yup.date()
      .nullable()
      .required('Date of Birth is required')
      .max(new Date(), 'Date of Birth cannot be in the future'),
    address: Yup.object().shape({
      city: Yup.string().required('City is required'),
      country: Yup.string().required('Country is required'),
      line1: Yup.string().required('Address Line 1 is required'),
      line2: Yup.string(), // Line 2 is optional
      postalCode: Yup.string().required('Postal Code is required'),
      state: Yup.string().required('State is required'),
    }),
    id_number: Yup.string()
      .length(9, 'Identity number must be only 9 digits')
      .matches(/^\d{9}$/, 'Identity number must contain only digits')
      .required('Last 4 digits of Identity number are required'),
  }),
  bankDetails: Yup.object().shape({
    country: Yup.string().required('Country is required'),
    currency: Yup.string().required('Currency is required'),
    accountHolderType: Yup.string().required('Account Holder Type is required'),
    routingNumber: Yup.string().required('Routing Number is required'),
    accountNumber: Yup.number().required('Account Number is required'),
    confirmAccountNumber: Yup.number()
      .required('Account Number is required')
      .test('accountNumbersMatch', 'Account Numbers must match', function (value) {
        return value === this.parent.accountNumber;
      }),
    
  }),
});


const countries = [
  { name: 'United States', code: 'US', currency: 'USD' },
  { name: 'Australia', code: 'AU', currency: 'AUD' },
  { name: 'India', code: 'IN', currency: 'INR' },
  { name: 'Japan', code: 'JP', currency: 'JPY' },
  { name: 'Austria', code: 'AT', currency: 'EUR' },
  { name: 'Belgium', code: 'BE', currency: 'EUR' },
  { name: 'Brazil', code: 'BR', currency: 'BRL' },
  { name: 'Bulgaria', code: 'BG', currency: 'BGN' },
  { name: 'Canada', code: 'CA', currency: 'CAD' },
  { name: 'Croatia', code: 'HR', currency: 'EUR' },
  { name: 'Cyprus', code: 'CY', currency: 'EUR' },
  { name: 'Czech Republic', code: 'CZ', currency: 'CZK' },
  { name: 'Denmark', code: 'DK', currency: 'DKK' },
  { name: 'Estonia', code: 'EE', currency: 'EUR' },
  { name: 'Finland', code: 'FI', currency: 'EUR' },
  { name: 'France', code: 'FR', currency: 'EUR' },
  { name: 'Germany', code: 'DE', currency: 'EUR' },
  { name: 'Ghana', code: 'GH', currency: 'GHS' },
  { name: 'Gibraltar', code: 'GI', currency: 'GIP' },
  { name: 'Greece', code: 'GR', currency: 'EUR' },
  { name: 'Hong Kong', code: 'HK', currency: 'HKD' },
  { name: 'Hungary', code: 'HU', currency: 'HUF' },
  { name: 'Indonesia', code: 'ID', currency: 'IDR' },
  { name: 'Ireland', code: 'IE', currency: 'EUR' },
  { name: 'Italy', code: 'IT', currency: 'EUR' },
  { name: 'Kenya', code: 'KE', currency: 'KES' },
  { name: 'Latvia', code: 'LV', currency: 'EUR' },
  { name: 'Liechtenstein', code: 'LI', currency: 'CHF' },
  { name: 'Lithuania', code: 'LT', currency: 'EUR' },
  { name: 'Luxembourg', code: 'LU', currency: 'EUR' },
  { name: 'Malaysia', code: 'MY', currency: 'MYR' },
  { name: 'Malta', code: 'MT', currency: 'EUR' },
  { name: 'Mexico', code: 'MX', currency: 'MXN' },
  { name: 'Netherlands', code: 'NL', currency: 'EUR' },
  { name: 'New Zealand', code: 'NZ', currency: 'NZD' },
  { name: 'Nigeria', code: 'NG', currency: 'NGN' },
  { name: 'Norway', code: 'NO', currency: 'NOK' },
  { name: 'Poland', code: 'PL', currency: 'PLN' },
  { name: 'Portugal', code: 'PT', currency: 'EUR' },
  { name: 'Romania', code: 'RO', currency: 'RON' },
  { name: 'Singapore', code: 'SG', currency: 'SGD' },
  { name: 'Slovakia', code: 'SK', currency: 'EUR' },
  { name: 'Slovenia', code: 'SI', currency: 'EUR' },
  { name: 'South Africa', code: 'ZA', currency: 'ZAR' },
  { name: 'Spain', code: 'ES', currency: 'EUR' },
  { name: 'Sweden', code: 'SE', currency: 'SEK' },
  { name: 'Switzerland', code: 'CH', currency: 'CHF' },
  { name: 'Thailand', code: 'TH', currency: 'THB' },
  { name: 'United Arab Emirates', code: 'AE', currency: 'AED' },
  { name: 'United Kingdom', code: 'GB', currency: 'GBP' },
];

export default function BankDetailsForm({handleClose, handleSave, isLoading, isCompany }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    values = {
      individual: {
        firstName: values.individual.firstName,
        lastName: values.individual.lastName,
        phone: values.individual.phone,
        email: values.individual.email,
        dob: {
          day: values.individual?.dob.date(),
          month: values.individual?.dob.month() + 1,
          year: values.individual?.dob.year(),
        },
        address: {
          city: values.individual.address.city,
          country: values.individual.address.country,
          line1: values.individual.address.line1,
          line2: values.individual.address.line2,
          postal_code: values.individual.address.postalCode,
          state: values.individual.address.state,
        },
        id_number: values.individual.id_number,
      },
      bankDetails: {
        country: values.bankDetails.country,
        currency: values.bankDetails.currency,
        accountHolderName: `${values.individual.firstName} ${values.individual.lastName}`,
        accountHolderType: values.bankDetails.accountHolderType,
        accountNumber: values.bankDetails.accountNumber,
        confirmAccountNumber: values.bankDetails.confirmAccountNumber,
        routingNumber: values.bankDetails.routingNumber,
        swiftNumber: values.bankDetails.swiftNumber,
      },
      ...(isCompany && { company: { name: values.company.name, taxId: values.company.taxId } }),
    };
        
    console.log('values', values);
    handleSave(values)
  };


  return (
    <Formik
      initialValues={{
        individual: {
          firstName: '',
          lastName: '',
          phone: '',
          email: '',
          dob: '',
          address: {
            city: '',
            country: '',
            line1: '',
            line2: '',
            postalCode: '',
            state: '',
          },
          id_number:''
        },
        bankDetails: {
          country: '',
          currency: '',
          accountHolderName: '',
          accountHolderType: '',
          routingNumber: '',
          accountNumber: '',
        },
        ...(isCompany && { company: { name: "", taxId: "" } }),
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ isSubmitting, touched, errors, handleChange, values, resetForm, setFieldValue }) => (
        <Form>
          <Grid container spacing={2} pt={1}>
            {isCompany && <>
              <Grid item xs={12} md={12} pt={1}>
              <Typography fontWeight={600}>Site/CRO Details</Typography>
            </Grid>
            <Grid item xs={12} md={6} pb={2}>
              <TextField
                fullWidth
                name="company.name"
                value={values.company?.name}
                onChange={handleChange}
                label="Site/CRO name"
                size="small"
                error={touched.company?.name && Boolean(errors.company?.name)}
                helperText={touched.company?.name && errors.company?.name}
              />
            </Grid>
            <Grid item xs={12} md={6} pb={2}>
              <TextField
                fullWidth
                name="company.taxId"
                value={values.company?.taxId}
                onChange={handleChange}
                label="Tax Id"
                size="small"
                error={touched.company?.taxId && Boolean(errors.company?.taxId)}
                helperText={touched.company?.taxId && errors.company?.taxId}
              />
            </Grid>
</>}
          
            <Grid item xs={12} md={12} pt={1}>
              <Typography fontWeight={600}>Representative Details</Typography>
            </Grid>
            <Grid item xs={12} md={6} pb={2}>
              <TextField
                fullWidth
                name="individual.firstName"
                value={values.individual?.firstName}
                onChange={handleChange}
                label="First Name"
                size="small"
                error={touched.individual?.firstName && Boolean(errors.individual?.firstName)}
                helperText={touched.individual?.firstName && errors.individual?.firstName}
              />
            </Grid>
            <Grid item xs={12} md={6} pb={2}>
              <TextField
                fullWidth
                name="individual.lastName"
                value={values.individual?.lastName}
                onChange={handleChange}
                label="Last Name"
                size="small"
                error={touched.individual?.lastName && Boolean(errors.individual?.lastName)}
                helperText={touched.individual?.lastName && errors.individual?.lastName}
              />
            </Grid>
            <Grid item xs={12} md={6} pb={2}>
              <TextField
                fullWidth
                type="email"
                name="individual.email"
                value={values.individual?.email}
                onChange={handleChange}
                label="Email"
                size="small"
                error={touched.individual?.email && Boolean(errors.individual?.email)}
                helperText={touched.individual?.email && errors.individual?.email}
              />
            </Grid>
            <Grid item xs={12} md={6} pb={2}>
              <Field name="individual.phone" component={Phone} label="Contact Number" />
            </Grid>
            <Grid item xs={12} md={6} pb={2} pt={0}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']}>
                  <DatePicker
                    label="Date of Birth"
                    disableFuture
                    value={values.individual?.dob || null}
                    onChange={(newValue) => {
                      setFieldValue('individual.dob', newValue);
                    }}
                    sx={{pt:0}}
                    slotProps={{
                      textField: {
                        size: 'small',
                        error: touched.individual?.dob && Boolean(errors.individual?.dob),
                        helperText: touched.individual?.dob && errors.individual?.dob,
                        fullWidth: true,
                        pt: 0,
                      },
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={6} pb={2}>
              <TextField
                fullWidth
                name="individual.id_number"
                value={values.individual?.id_number}
                onChange={handleChange}
                label="Social Security Number"
                size="small"
                sx={{pt:1}}
                error={touched.individual?.id_number && Boolean(errors.individual?.id_number)}
                helperText={touched.individual?.id_number && errors.individual?.id_number}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography fontWeight={600}>Address</Typography>
            </Grid>
            <Grid item xs={12} md={6} pb={2}>
              <TextField
                fullWidth
                name="individual.address.line1"
                value={values.individual?.address?.line1}
                onChange={handleChange}
                label="Address Line 1"
                size="small"
                error={touched.individual?.address?.line1 && Boolean(errors.individual?.address?.line1)}
                helperText={touched.individual?.address?.line1 && errors.individual?.address?.line1}
              />
            </Grid>
            <Grid item xs={12} md={6} pb={2}>
              <TextField
                fullWidth
                name="individual.address.line2"
                value={values.individual?.address?.line2}
                onChange={handleChange}
                label="Address Line 2"
                size="small"
                error={touched.individual?.address?.line2 && Boolean(errors.individual?.address?.line2)}
                helperText={touched.individual?.address?.line2 && errors.individual?.address?.line2}
              />
            </Grid>
            <Grid item xs={12} md={6} pb={2}>
              <TextField
                fullWidth
                name="individual.address.city"
                value={values.individual?.address?.city}
                onChange={handleChange}
                label="City"
                size="small"
                error={touched.individual?.address?.city && Boolean(errors.individual?.address?.city)}
                helperText={touched.individual?.address?.city && errors.individual?.address?.city}
              />
            </Grid>
            <Grid item xs={12} md={6} pb={2}>
              <TextField
                fullWidth
                name="individual.address.state"
                value={values.individual?.address?.state}
                onChange={handleChange}
                label="State"
                size="small"
                error={touched.individual?.address?.state && Boolean(errors.individual?.address?.state)}
                helperText={touched.individual?.address?.state && errors.individual?.address?.state}
              />
            </Grid>
            <Grid item xs={12} md={6} pb={2}>
              <TextField
                fullWidth
                name="individual.address.postalCode"
                value={values.individual?.address?.postalCode}
                onChange={handleChange}
                label="Postal Code"
                size="small"
                error={touched.individual?.address?.postalCode && Boolean(errors.individual?.address?.postalCode)}
                helperText={touched.individual?.address?.postalCode && errors.individual?.address?.postalCode}
              />
            </Grid>
            <Grid item xs={12} md={6} pb={2}>
              <TextField
                fullWidth
                name="individual.address.country"
                value={values.individual?.address?.country}
                onChange={handleChange}
                label="Country"
                size="small"
                error={touched.individual?.address?.country && Boolean(errors.individual?.address?.country)}
                helperText={touched.individual?.address?.country && errors.individual?.address?.country}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography fontWeight={600}>Bank Details</Typography>
            </Grid>
            <Grid item xs={12} md={6} pb={2}>
              <TextField
                fullWidth
                name="bankDetails.accountNumber"
                value={values.bankDetails?.accountNumber}
                onChange={handleChange}
                label="Account Number"
                size="small"
                error={touched.bankDetails?.accountNumber && Boolean(errors.bankDetails?.accountNumber)}
                helperText={touched.bankDetails?.accountNumber && errors.bankDetails?.accountNumber}
              />
            </Grid>
            <Grid item xs={12} md={6} pb={2}>
              <TextField
                fullWidth
                name="bankDetails.confirmAccountNumber"
                value={values.bankDetails?.confirmAccountNumber}
                onChange={handleChange}
                label="Confirm Account Number"
                size="small"
                error={touched.bankDetails?.confirmAccountNumber && Boolean(errors.bankDetails?.confirmAccountNumber)}
                helperText={touched.bankDetails?.confirmAccountNumber && errors.bankDetails?.confirmAccountNumber}
              />
            </Grid>
            <Grid item xs={12} md={6} pb={2}>
              <TextField
                fullWidth
                name="bankDetails.routingNumber"
                value={values.bankDetails?.routingNumber}
                onChange={handleChange}
                label="Routing Number"
                size="small"
                error={touched.bankDetails?.routingNumber && Boolean(errors.bankDetails?.routingNumber)}
                helperText={touched.bankDetails?.routingNumber && errors.bankDetails?.routingNumber}
              />
            </Grid>
            <Grid item xs={12} md={6} pb={2}>
              <TextField
                fullWidth
                name="bankDetails.accountHolderType"
                value={values.bankDetails?.accountHolderType}
                onChange={handleChange}
                label="Account Holder Type"
                size="small"
                error={touched.bankDetails?.accountHolderType && Boolean(errors.bankDetails?.accountHolderType)}
                helperText={touched.bankDetails?.accountHolderType && errors.bankDetails?.accountHolderType}
              />
            </Grid>

            <Grid item xs={12} md={6} pb={2}>
              <FormControl
                fullWidth
                error={touched.bankDetails?.country && Boolean(errors.bankDetails?.country)}
                size="small"
              >
                <InputLabel id="bankDetails.country-label">Bank Country</InputLabel>
                <Select
                  labelId="bankDetails.country-label"
                  id="bankDetails.country"
                  name="bankDetails.country"
                  value={values.bankDetails?.country}
                  onChange={(event) => {
                    const selectedCountry = countries.find((country) => country.code === event.target.value);
                    setFieldValue('bankDetails.country', event.target.value);
                    setFieldValue('bankDetails.currency', selectedCountry?.currency);
                  }}
                  label="Bank Country"
                >
                  <MenuItem disabled value="">
                    <em style={{ color: '#aaa', fontStyle: 'normal' }}>Bank Country</em>
                  </MenuItem>
                  {countries.map((option) => {
                    return (
                      <MenuItem key={option?.code} value={option?.code}>
                        {option?.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText>{touched.bankDetails?.country && errors.bankDetails?.country}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} pb={2}>
              <TextField
                fullWidth
                name="bankDetails.currency"
                value={values.bankDetails?.currency}
                // onChange={handleChange},
                disabled
                label="Currency"
                size="small"
                error={touched.bankDetails?.currency && Boolean(errors.bankDetails?.currency)}
                helperText={touched.bankDetails?.currency && errors.bankDetails?.currency}
              />
            </Grid>
            <Grid item xs={12} display={'flex'} justifyContent={'center'} mb={0}>
              <Box paddingRight={1.5}>
                <CustomButton variant="outlined" onClick={() => handleClose()} disabled={isSubmitting || isLoading}>
                  Cancel
                </CustomButton>
              </Box>
              <Box>
                <CustomButton type="submit" variant="contained" disabled={isSubmitting || isLoading}>
                {isSubmitting || isLoading ? <CircularProgress size={25} style={{color:'white'}}/> : 'Submit'}
                </CustomButton>
              </Box>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}
