import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CustomCard from '../../../components/@extended/CustomCard';
import { Box, CardContent, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip, Typography, useTheme } from '@mui/material';
import { CustomTableHead, CustomTableHeadCell } from '../../../components/@extended/CustomTable';
import { useGetSiteInvoicesQuery } from '../../../store/slices/apiSlice';
import { queryAllByAttribute } from '@testing-library/react';
import moment from 'moment';

const columns = [
  {
    id: 'invoiceNumber',
    label: 'Invoice Number',
    minWidth: 170,
    align: 'left',
  },
  {
    id: 'invoiceDate',
    label: 'Invoice Date',
    minWidth: 170,
    align: 'center',
  },
  {
    id: 'dueDate',
    label: 'Due Date',
    minWidth: 170,
    align: 'center',
  },
  {
    id: 'amount',
    label: 'Amount',
    minWidth: 170,
    align: 'center',
  },
  { id: 'status', label: 'Status', minWidth: 170, align: 'center' },
  // { id: 'action', label: '', minWidth: 100, align: 'center' },
];

const FinanceTrialSiteInvoicesCard = () => {
  const dispatch = useDispatch();
    const { trialId, siteId } = useParams();
    const theme = useTheme()
  const selectedSite = useSelector((state) => state.finance?.currentSite);
    const { data } = useGetSiteInvoicesQuery(
      { query: `?issuerEntityId=${siteId}` },
      { skip: !siteId }
    );

    const rows = useMemo(() => {
      if (!data) return [];

      return data?.map((_inv) => ({
        invoiceNumber: _inv.invoiceDetails.invoiceNumber,
        invoiceDate: _inv.invoiceDetails.invoiceDate
          ? moment(_inv.invoiceDetails.invoiceDate, 'DD-MM-YYYY').format('DD MMM YYYY')
          : 'N/A',
        dueDate: _inv.invoiceDetails.dueDate
          ? moment(_inv.invoiceDetails.dueDate, 'DD-MM-YYYY').format('DD MMM YYYY')
          : 'N/A',
        amount: _inv?.amounts.amount,
        status: _inv?.invoiceDetails?.status,
        id: _inv?.id,
      }));
    }, [data]);

    return (
      <>
        <CustomCard title="Invoices">
          <CardContent sx={{ p: 0 }}>
            <TableContainer sx={{ maxHeight: 400 }}>
              <Table stickyHeader aria-label="sticky table">
                <CustomTableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <CustomTableHeadCell key={column.id + column.label + 'columns'} align={column.align}>
                        {column.label}
                      </CustomTableHeadCell>
                    ))}
                  </TableRow>
                </CustomTableHead>
                <TableBody>
                  {rows &&
                    rows?.map((row, index) => {
                      return (
                        <TableRow hover tabIndex={-1} key={index} sx={{ cursor: 'pointer' }}>
                          {columns.map((column) => {
                            if (column.id === 'amount') {
                              return (
                                <TableCell key={index + column.id} align={column.align}>
                                  <Tooltip
                                    title={
                                      <Box sx={{ p: 1 }}>
                                        <Typography variant="body2">Tax: ${row.tax}</Typography>
                                        <Typography variant="body2">Stripe Fees: ${row.stripeFees}</Typography>
                                        <Typography variant="body2">
                                          Application fees: ${row.applicationFees === 0 ? 0.1 : 0.1}
                                        </Typography>
                                        <Typography variant="body2">Total: ${row.totalAmount}</Typography>
                                      </Box>
                                    }
                                    arrow
                                  >
                                    <Typography variant="subtitle2" color="primary">
                                      ${row[column.id]}
                                    </Typography>
                                  </Tooltip>
                                </TableCell>
                              );
                            }
                            if (column.id === 'status') {
                              return (
                                <TableCell key={index + column.id} align={column.align}>
                                  <Typography
                                    variant="subtitle2"
                                    sx={{ color: row[column.id] === 'APPROVED' ? 'green' : theme.palette.warning.main }}
                                    fontWeight={600}
                                  >
                                    {row[column.id]}
                                  </Typography>
                                </TableCell>
                              );
                            }
                            if (column.id === 'event') {
                              return (
                                <TableCell key={index + column.id} align={column.align}>
                                  <Typography
                                    variant="subtitle2"
                                    sx={{ color: row[column.id] === 'succeeded' ? 'green' : 'inherit' }}
                                  >
                                    {row[column.id]}
                                  </Typography>
                                </TableCell>
                              );
                            }
                            return (
                              <TableCell key={row[column.id] + column.label + index + 'Site data'} align={column.align}>
                                <Typography variant="subtitle2" color="initial">
                                  {row[column.id]}
                                </Typography>
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  {rows.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={columns?.length} align="center">
                        <Typography variant="subtitle2" color="initial">
                          No Transactions found.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </CustomCard>
      </>
    );
};

export default FinanceTrialSiteInvoicesCard;
