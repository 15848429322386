import React, { useMemo, useRef, useState } from 'react';
import CustomCard from '../../../components/@extended/CustomCard';
import {
  Box,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import CustomButton from '../../../components/@extended/CustomButton';
import { useParams } from 'react-router-dom';
import {
  useDownloadSiteTransactionsMutation,
  useGetSiteTransactionsQuery,
  useUploadTriggerPaymentCsvMutation,
} from '../../../store/slices/apiSlice';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { VisuallyHiddenInput } from '../../../components/common/DocumentUpload';
import { allowedFileTypes } from '../../createFinanceTrial/FinSiteDetailsSection';
import { useDispatch, useSelector } from 'react-redux';
import { openMessage } from '../../../store/slices/showMessageSlice';
import { CustomTableHead, CustomTableHeadCell } from '../../../components/@extended/CustomTable';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import ConfirmationDialog from '../../../components/common/ConfirmationDialog';

const columns = [
  {
    id: 'recipientEmail',
    label: 'Recipient Email',
    minWidth: 170,
    align: 'left',
  },
  {
    id: 'event',
    label: 'Event',
    minWidth: 170,
    align: 'center',
  },
  {
    id: 'amount',
    label: 'Amount',
    minWidth: 170,
    align: 'center',
  },
  { id: 'status', label: 'Status', minWidth: 170, align: 'center' },
  { id: 'action', label: '', minWidth: 100, align: 'center' },
];

const SiteTransactionsCard = () => {
  const dispatch = useDispatch();
  const { trialId, siteId } = useParams();
  const [downloadSiteTransactions] = useDownloadSiteTransactionsMutation();
  const { data } = useGetSiteTransactionsQuery({ query: `?clinicalStudyId=${trialId}` });
  const currentSite = useSelector((state) => state.finance.currentSite);
  const [uploadTriggerPaymentCsv] = useUploadTriggerPaymentCsvMutation();
  const fileInputRef = useRef(null);

  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    message: (
      <>
        <span>Are you sure you want to download this file?</span>
      </>
    ),
    buttonLabel: 'Download',
  });

  const handleFileUpload = async (acceptedFiles) => {
    const formData = new FormData();
    formData.append('file', acceptedFiles[0]);

    const result = await uploadTriggerPaymentCsv({
      protocolNumber: currentSite?.clinicalStudy?.protocolNumber,
      siteEntityId: siteId,
      body: formData,
    });

    dispatch(
      openMessage({
        messageSeverity: 'success',
        message: 'Document Uploaded Successfully!',
      })
    );

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
      fileInputRef.current.blur();
    }
  };
  const rows = useMemo(() => {
    if (!data?.transactions) return [];

    return data.transactions.map((_trans) => ({
      recipientEmail: _trans?.recipientEntity?.email || '- -',
      status: _trans?.transactionStatus,
      amount: (_trans?.amount / 100).toFixed(2),
      event: _trans?.event?.eventName,
      totalAmount: ((_trans?.amount + _trans?.stripeTransactionFees + _trans?.tax) / 100).toFixed(2),
      stripeFees: (_trans?.stripeTransactionFees / 100).toFixed(2),
      tax: (_trans?.tax / 100).toFixed(2),
      applicationFees: (_trans?.applicationFees / 100).toFixed(2),
    }));
  }, [data]);

  const handleConfirmDialogClose = () => {
    setConfirmDialog({
      ...confirmDialog,
      open: false,
    });
  };

  const handleDownloadCSV = async () => {
    try {
      const response = await downloadSiteTransactions({ query: `?clinicalStudyId=${trialId}` }).unwrap();
      // Ensure response is handled as a Blob
      const blob = new Blob([response], { type: 'text/csv' });

      // Extract filename from content-disposition header
      let fileName = 'download.csv'; // Default name

      // Create a download link
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();

      // Cleanup
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);

      
      dispatch(
          openMessage({
              messageSeverity: 'success',
              message: 'Template csv downloaded successfully!',
            })
        );
    } catch (error) {
        dispatch(
            openMessage({
                messageSeverity: 'error',
                message: 'Download Failed!',
            })
        );
    }
    handleConfirmDialogClose()
  };

  return (
    <>
      <CustomCard
        title="Transactions"
        action={
          <Box>
            <CustomButton
              variant="outlined"
              component="label"
              sx={{ mr: 2 }}
              onClick={() => {
                // setSelectedSite(site);
                //   handleDownloadCSV()
                setConfirmDialog({
                  ...confirmDialog,
                  open: true,
                });
              }}
              startIcon={<FileDownloadOutlinedIcon />}
            >
              Export Transactions
            </CustomButton>
            <CustomButton
              variant="outlined"
              component="label"
              onClick={() => {
                // setSelectedSite(site);
              }}
              startIcon={<CloudUploadOutlinedIcon />}
            >
              Make Payment
              <VisuallyHiddenInput
                ref={fileInputRef}
                onChange={async (e) => {
                  await handleFileUpload(e.target.files);
                }}
                type="file"
                accept={allowedFileTypes}
              />
            </CustomButton>
          </Box>
        }
      >
        <CardContent sx={{ p: 0 }}>
          <TableContainer sx={{maxHeight:400}}>
            <Table stickyHeader aria-label="sticky table">
              <CustomTableHead>
                <TableRow>
                  {columns.map((column) => (
                    <CustomTableHeadCell key={column.id + column.label + 'columns'} align={column.align}>
                      {column.label}
                    </CustomTableHeadCell>
                  ))}
                </TableRow>
              </CustomTableHead>
              <TableBody>
                {rows &&
                  rows?.map((row, index) => {
                    return (
                      <TableRow hover tabIndex={-1} key={index} sx={{ cursor: 'pointer' }}>
                        {columns.map((column) => {
                          if (column.id === 'amount') {
                            return (
                              <TableCell key={index + column.id} align={column.align}>
                                <Tooltip
                                  title={
                                    <Box sx={{ p: 1 }}>
                                      <Typography variant="body2">Tax: ${row.tax}</Typography>
                                      <Typography variant="body2">Stripe Fees: ${row.stripeFees}</Typography>
                                      <Typography variant="body2">
                                        Application fees: ${row.applicationFees === 0 ? 0.1 : 0.1}
                                      </Typography>
                                      <Typography variant="body2">Total: ${row.totalAmount}</Typography>
                                    </Box>
                                  }
                                  arrow
                                >
                                  <Typography variant="subtitle2" color="primary">
                                    ${row[column.id]}
                                  </Typography>
                                </Tooltip>
                              </TableCell>
                            );
                          }
                          if (column.id === 'status') {
                            return (
                              <TableCell key={index + column.id} align={column.align}>
                                <Typography
                                  variant="subtitle2"
                                        sx={{ color: row[column.id] === 'succeeded' ? 'green' : 'inherit' }}
                                        fontWeight={600}
                                >
                                  {row[column.id]}
                                </Typography>
                              </TableCell>
                            );
                          }
                          if (column.id === 'event') {
                            return (
                              <TableCell key={index + column.id} align={column.align}>
                                <Typography
                                  variant="subtitle2"
                                  sx={{ color: row[column.id] === 'succeeded' ? 'green' : 'inherit' }}
                                >
                                  {row[column.id]}
                                </Typography>
                              </TableCell>
                            );
                          }
                          return (
                            <TableCell key={row[column.id] + column.label + index + 'Site data'} align={column.align}>
                              <Typography variant="subtitle2" color="initial">
                                {row[column.id]}
                              </Typography>
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                {rows.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={columns?.length} align="center">
                      <Typography variant="subtitle2" color="initial">
                        No invoices found.
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </CustomCard>
      <ConfirmationDialog
        open={confirmDialog.open}
        buttonLabel={confirmDialog.buttonLabel}
        message={confirmDialog.message}
        handleClose={handleConfirmDialogClose}
        handleConfirm={handleDownloadCSV}
      />
    </>
  );
};

export default SiteTransactionsCard;
