import React from 'react';
import { Paper, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { CustomTableHead, CustomTableHeadCell, CustomTableRow } from '../../components/@extended/CustomTable';

const EventsSection = ({events}) => {
    const theme = useTheme();
  return (
    <Box sx={{ width: '100%'}}>
      {/* <Box sx={{ width: '100%', height: 3, backgroundColor: theme.palette.primary.light, borderRadiusTop: 5 }} /> */}
      {events.map((event) => (
        <Box key={event.id} sx={{ mb: 4 }}>
          {/* Event Details Section */}
          <Typography variant="subtitle1" sx={{ mb: 1 }}>{event.eventName}</Typography>
          <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 2, mb: 2 }}>
            <Typography variant="body2"><strong>Event Key:</strong> {event.eventKey}</Typography>
            <Typography variant="body2"><strong>Type:</strong> {event.type}</Typography>
            <Typography variant="body2"><strong>Approval Needed:</strong> {event.approvalNeeded ? 'Yes' : 'No'}</Typography>
            <Typography variant="body2"><strong>Subject Payment:</strong> {event.subjectPaymentAmount} {event.subjectPaymentCurrency.toUpperCase()}</Typography>
            {/* <Typography variant="body2"><strong>Created At:</strong> {new Date(event.createdAt).toLocaleString()}</Typography> */}
          </Box>

          {/* Event Payout Table */}
          {event.eventPayoutDetails.length > 0 && (
            <TableContainer>
              <Table size="small">
                <CustomTableHead>
                  <TableRow>
                    <CustomTableHeadCell><strong>Recipient</strong></CustomTableHeadCell>
                    {/* <CustomTableHeadCell><strong>Type</strong></CustomTableHeadCell> */}
                    <CustomTableHeadCell><strong>Amount</strong></CustomTableHeadCell>
                    <CustomTableHeadCell><strong>Currency</strong></CustomTableHeadCell>
                    <CustomTableHeadCell><strong>Email</strong></CustomTableHeadCell>
                    <CustomTableHeadCell><strong>Account Status</strong></CustomTableHeadCell>
                  </TableRow>
                </CustomTableHead>
                <TableBody>
                  {event.eventPayoutDetails.map((payout) => (
                    <CustomTableRow key={payout.id}>
                      <TableCell>{payout.targetEntity.addditionalInfo ? JSON.parse(payout.targetEntity.addditionalInfo).name : 'N/A'}</TableCell>
                      {/* <TableCell>{payout.targetEntity.type}</TableCell> */}
                      <TableCell>$ {payout.amount}</TableCell>
                      <TableCell>{payout.currency.toUpperCase()}</TableCell>
                      <TableCell>{payout.targetEntity.email}</TableCell>
                      <TableCell sx={{
                              color:
                              payout.targetEntity?.accountStatus === 'Pending'
                                  ? theme.palette.warning.main
                                  : theme.palette.success.main,
                              fontWeight: '700',
                            }}>{payout.targetEntity.accountStatus}</TableCell>
                    </CustomTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
              )}<Box sx={{pt:2}}> <Divider /></Box>
             
        </Box>
      ))}
    </Box>
  )
}

export default EventsSection