import {
  Box,
  Button,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';
import RightArrow from '../../components/icons/RightArrow';
import { useNavigate, useParams } from 'react-router-dom';
import FinanceTrialDetailsForm from './FinanceTrialDetailsForm';
import FinSiteDetailsSection from './FinSiteDetailsSection';
import {  useGetFianceTrialByIdQuery } from '../../store/slices/apiSlice';
import { useDispatch } from 'react-redux';
import DownloadTemplate from '../../components/common/DownloadTemplate';

const CreateFinanceTrial = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { trialId } = useParams();
  // eslint-disable-next-line no-unused-vars
  const { data } = useGetFianceTrialByIdQuery(trialId, { skip: !trialId });
  

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          flex: 'grow',
          display: 'flex',
          height: 70,
        }}
      >
        <Button
          style={{ position: 'absolute', top: 12 }}
          onClick={() => navigate(-1)}
          startIcon={<RightArrow leftArrow />}
        >
          <Typography variant="subtitle1" sx={{ textTransform: 'none' }} color={theme.palette.primary.light}>
            Dashboard
          </Typography>
        </Button>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6" color="initial" ml={'auto'} mr={'auto'}>
            Clinical Trial Set-up
          </Typography>
        </Box>
        <Box style={{ position: 'absolute', right: 5, top: 12 }}>
          <DownloadTemplate />
        </Box>
      </Box>
      <Grid container py={2} rowGap={2}>
        {/* <Grid item xs={12}> */}
        <FinanceTrialDetailsForm />
        {/* </Grid> */}
        {trialId && (
          <>
            <FinSiteDetailsSection />
          </>
        )}
      </Grid>
    </>
  );
};

export default CreateFinanceTrial;
