import React, { useState } from 'react'
import { Box, Button, CardContent, Dialog, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Typography, useTheme } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import RightArrow from '../../../components/icons/RightArrow';
import FinanceTrialSiteDetailsCard from './FinanceTrialSiteDetailsCard';
import { useGetFinanceSiteDetailsQuery } from '../../../store/slices/apiSlice';
import { useDispatch, useSelector } from 'react-redux';
import { setFinanceSite } from '../../../store/slices/financeSlice';
import FinanceSiteBudgetPie from './FinanceSiteBudgetPie';
import CustomButton from '../../../components/@extended/CustomButton';
import DownloadTemplate from '../../../components/common/DownloadTemplate';
import CloseIcon from '@mui/icons-material/Close';
import EventsSection from '../../createFinanceTrial/EventsSection';
import SiteTransactionsCard from './SiteTransactionsCard';
import GenerateInvoice from './GenerateInvoice';
import FinanceTrialSiteInvoicesCard from './FinanceTrialSiteInvoicesCard';
import FinanceSiteExpenditureChart from './FinanceSiteExpenditureChart';

const FinanceTrialSitedetails = () => {
    const navigate = useNavigate();
    const { trialId, siteId } = useParams();
    const dispatch = useDispatch();
    useGetFinanceSiteDetailsQuery(
      {
        trialId,
        siteId,
      },
      { skip: !trialId || !siteId, refetchOnMountOrArgChange: true }
    );
    const [openSiteBudget, setOpenSiteBudget] = useState(false);
    const [openGenerateInvoice, setOpenGenerateInvoice] = useState(false);
  
    const handleBack = () => {
      navigate(-1);
      dispatch(setFinanceSite(null));
    };

    const selectedSite = useSelector((state) => state.finance?.currentSite);

    return (
      <>
        <Grid container rowGap={2}>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button type="text" onClick={handleBack} startIcon={<RightArrow leftArrow />}>
                <Typography variant="subtitle1" sx={{ textTransform: 'none' }}>
                  Back to Study Details
                </Typography>
              </Button>
              <Box>
                <CustomButton variant="outlined" onClick={() => setOpenGenerateInvoice(true)} sx={{ mr: 2 }}>
                  Generate Invoice
                </CustomButton>
                <CustomButton variant="contained" onClick={() => setOpenSiteBudget(true)} sx={{ mr: 2 }}>
                  View Budget
                </CustomButton>
                <DownloadTemplate />
              </Box>
            </Box>
          </Grid>
          <Grid container direction={'row'} columnSpacing={3}>
            <Grid item sm={12} md={8}>
              <Box sx={{ pb: 3 }}>
                <FinanceTrialSiteDetailsCard />
              </Box>
              <Box sx={{ pb: 3 }}>
                <SiteTransactionsCard />
              </Box>
              <Box sx={{ pb: 0 }}>
                <FinanceTrialSiteInvoicesCard />
              </Box>
            </Grid>
            <Grid item md={4} sm={12}>
              <Box sx={{ pb: 3 }}>
                <FinanceSiteBudgetPie />
              </Box>
              <Box sx={{ pb: 3 }}>
                <FinanceSiteExpenditureChart />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Dialog
          fullWidth
          maxWidth={'md'}
          scroll={'paper'}
          open={openSiteBudget}
          onClose={() => {
            setOpenSiteBudget(false);
          }}
          PaperProps={{
            sx: {
              maxWidth: 'md',
            },
          }}
        >
          <DialogTitle>Budget Events</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => setOpenSiteBudget(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <EventsSection events={selectedSite?.events} />
          </DialogContent>
        </Dialog>
        <Dialog
          fullWidth
          maxWidth={'md'}
          scroll={'paper'}
          open={openGenerateInvoice}
          onClose={() => {
            setOpenGenerateInvoice(false);
          }}
          PaperProps={{
            sx: {
              maxWidth: 'md',
            },
          }}
        >
          <DialogTitle>Generate Invoice</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => setOpenGenerateInvoice(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <GenerateInvoice handleClose={()=>{setOpenGenerateInvoice(false)}} />
          </DialogContent>
        </Dialog>
      </>
    );
}

export default FinanceTrialSitedetails