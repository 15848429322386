import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Tab,
    TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import { Field, Form, Formik, useField, useFormik } from "formik";
import PhoneInput from "react-phone-number-input";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { getIn } from "formik";
import * as Yup from "yup";
import { useAddSiteMemberMutation, useAddUserFinanceDetailMutation } from "../../../store/slices/apiSlice";
import { openMessage } from "../../../store/slices/showMessageSlice";
import CustomPhoneInput from "../../dynamicFormEngine/FormFields/CustomPhoneInput";
import Phone, {phoneValidationSchema} from "../../../components/common/Phone";
import StyledTabs from "../../../components/@extended/StyledTabs";
import { useState } from "react";
import BankDetailsForm from "../../../components/common/BankDetailsForm";
import CustomButton from "../../../components/@extended/CustomButton";
import { words } from "lodash";
import { display, gridColumnGap, justifyContent, width } from "styled-system";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && <>{children}</>}
      </div>
    );
}

const AddMember = ({ siteId, onAddMember, handleClose, open }) => {
    const dispatch = useDispatch()
    const openMessageNotification = (message) => {
        dispatch(openMessage({ message: message.message, messageSeverity: message.type }))
    };

    const sponsorId = useSelector((state) => state.auth.sponsorId);
    const [addSiteMember] = useAddSiteMemberMutation();
     const [addUserFinanceDetail, {isLoading}] = useAddUserFinanceDetailMutation() 
    const docSchema = Yup.object().shape({
        email: Yup.string()
            .required("Email is required")
            .email("Please enter a valid email")
            .matches(/^[a-z0-9.]+@[a-z0-9.-]+\.[a-z]{2,}$/, 'Email address can only contain lowercase letters, numbers, and periods.'),
        firstName: Yup.string().trim()
            .required("First name is required")
            .min(2, "Minimum 2 characters are required")
            .max(50, "Maximum 50 characters are allowed"),
        lastName: Yup.string().trim()
            .required("Last name is required")
            .min(2, "Minimum 2 characters are required")
            .max(50, "Maximum 50 characters are allowed"),
        address: Yup.string().trim()
            .required("Adress is required")
            .min(2, "Minimum 2 characters are required")
            .max(500, "Maximum 500 characters are allowed"),
        contactNumber:  phoneValidationSchema.concat(Yup.string().required('Contact number is required')),
        // .matches(/^\+[1-9]\d{0,2}[1-9]\d{6,14}$/, "Invalid contact number"),
    });

    const onCloseModal = (resetForm) => {
        resetForm();
        handleClose();
    }
   const [userId, setUserId] = useState(null)
    const [tabValue, setTabValue] = useState(0);
    
    const handleTabChange = (event ,newValue) => {
        setTabValue(newValue);
    };
      
    function a11yProps(index) {
      return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`,
      };
    }

    const handleCreateUser = async(values, resetForm) => {
        values = {
            email: values.email,
            firstName: values.firstName.trim(),
            lastName: values.lastName.trim(),
            address: values.address.trim(),
            contactNumber: values.contactNumber,
          };
          console.log(values);
          const result = await addSiteMember({
            data: { ...values, userName: values.email, siteId },
            sponsorId: sponsorId,
          });
          if (result.data) {
            openMessageNotification({
              message: 'Submitted Successfully',
              type: 'success',
            });
        setUserId(result.data.id)
          } else if (result.error) {
            if (result.error.data.message.includes('user_name', 'cannot contain duplicate values')) {
              openMessageNotification({
                message: 'User with given email Id is already Exists',
                type: 'error',
              });
            } else {
              openMessageNotification({
                message: 'Unable to Submit',
                type: 'error',
              });
            }
          }
        resetForm();
        setTabValue(1)
        //   onAddMember();
    }

    const handleUserFinance = async (data) => {
      try {
        const payload = { ...data, businessType: 'individual' };
        const res = await addUserFinanceDetail({
          payload: payload,
          userId,
        }).unwrap();
        if (res) {
          openMessageNotification({
            message: 'User Bank details added successfully.',
            type: 'success',
          });
          handleClose();
        }
      } catch (error) {
        console.error(error);
      }
    };
    
    return (
      <>
        <Formik
          initialValues={{
            email: '',
            firstName: '',
            lastName: '',
            address: '',
            contactNumber: '',
          }}
          validationSchema={docSchema}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            handleCreateUser(values, resetForm);
          }}
        >
          {({ isSubmitting, handleSubmit, handleChange, values, touched, errors, resetForm }) => (
            <Dialog
              //fullWidth={true}
              maxWidth={'sm'}
              //sx={{minHeight: '50%'}}
              //PaperProps={{ sx: { minHeight: "50%" } }}
              scroll={'paper'}
              open={open}
              onClose={() => onCloseModal(resetForm)}
              PaperProps={{
                sx: {
                  maxWidth: '800px',
                },
              }}
            >
              <DialogTitle>Add Member</DialogTitle>
              <IconButton
                aria-label="close"
                onClick={() => onCloseModal(resetForm)}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>

              <DialogContent sx={{ pt: 0, width: { xs: 700, md: 800 } }}>
                <StyledTabs
                  value={tabValue}
                  onChange={handleTabChange}
                  textColor="primary"
                  indicatorColor="primary"
                  variant="fullWidth"
                  sx={{
                    top: 0,
                    pb: 2,
                  }}
                >
                  <Tab label="1. Member Details" {...a11yProps(0)} />
                  <Tab label="2. Bank Details" {...a11yProps(1)} />
                </StyledTabs>
                <TabPanel value={tabValue} index={0}>
                  <Form>
                    <Grid container spacing={4} pt={2}>
                      <Grid item xs={12} md={6}>
                        <TextField
                          label="Email"
                          onChange={handleChange}
                          name="email"
                          fullWidth
                          value={values.email}
                          size="small"
                          variant="outlined"
                          helperText={touched.email && errors.email}
                          error={touched.email && Boolean(errors.email)}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field name="contactNumber" component={Phone} label="Contact Number" />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          label="First Name"
                          onChange={handleChange}
                          fullWidth
                          name="firstName"
                          value={values.firstName}
                          size="small"
                          variant="outlined"
                          helperText={touched.firstName && errors.firstName}
                          error={touched.firstName && Boolean(errors.firstName)}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          label="Last Name"
                          onChange={handleChange}
                          fullWidth
                          name="lastName"
                          value={values.lastName}
                          size="small"
                          variant="outlined"
                          helperText={touched.lastName && errors.lastName}
                          error={touched.lastName && Boolean(errors.lastName)}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <TextField
                          label="Address"
                          onChange={handleChange}
                          fullWidth
                          name="address"
                          value={values.address}
                          size="small"
                          variant="outlined"
                          helperText={touched.address && errors.address}
                          error={touched.address && Boolean(errors.address)}
                        />
                      </Grid>
                    </Grid>
                  </Form>

                  <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', columnGap: 2, pt: 3 }}>
                    <CustomButton
                      variant="outlined"
                      onClick={() => {
                        onCloseModal(resetForm);
                      }}
                    >
                      Close
                    </CustomButton>
                    <CustomButton variant="contained" onClick={() => handleSubmit()}>
                      Save
                    </CustomButton>
                  </Box>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                  <BankDetailsForm handleClose={handleClose} handleSave={handleUserFinance} isLoading={isLoading} />
                </TabPanel>
              </DialogContent>
            </Dialog>
          )}
        </Formik>
      </>
    );
}

export default AddMember;