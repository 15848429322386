import React, { useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  OutlinedInput,
  TextField,
  Select,
  Typography,
  useTheme,
  Tooltip,
  useMediaQuery,
  FormHelperText,
  Tab,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Button from '@mui/material/Button';
import CustomButton from '../../../components/@extended/CustomButton';
import { Field, Form, Formik, useFormik } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import {
  useAddDepartmentInMetaMutation,
  useAddSiteFinanceDetailMutation,
  useCreateSiteMutation,
} from '../../../store/slices/apiSlice';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import { useGetDepartmentMetaQuery } from '../../../store/slices/apiSlice';
import Phone, { phoneValidationSchema } from '../../../components/common/Phone';
import { openMessage } from '../../../store/slices/showMessageSlice';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import PinDropRoundedIcon from '@mui/icons-material/PinDropRounded';
import GoogleMapComponent from '../../../components/common/GoogleMap';
import GooglePlacesAutocompleteComponent from '../../../components/common/GooglePlacessAutocomplete';
import StyledTabs from '../../../components/@extended/StyledTabs';
import BankDetailsForm from '../../../components/common/BankDetailsForm';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

const AddSite = ({ handleClose, open, openMessageNotification }) => {
  const sponsorId = useSelector((state) => state.auth.sponsorId);
  const { data: deptMeta } = useGetDepartmentMetaQuery();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [showAddDept, setShowAddDept] = useState(false);
  const [dept, setDept] = useState('');
  const [address, setAddress] = useState(null);
  const [addressError, setAddressError] = useState('');
  const [addressDetails, setAddressDetails] = useState();
  const [openGoogleMap, setOpenGoogleMap] = useState(false); //to open and close google map
  const [currentAddress, setCurrentAddress] = useState(); //to set current address
  const [createSite] = useCreateSiteMutation();
  const [addDepartmentInMeta] = useAddDepartmentInMetaMutation();
  const matchDownMD = useMediaQuery(theme.breakpoints.down('md'));
  const [siteId, setSiteId] = useState(null)
  const [addSiteFinanceDetail,{isLoading}] = useAddSiteFinanceDetailMutation();

  const docSchema = Yup.object().shape({
    orgname: Yup.string()
      .required('Site name is required')
      .min(2, 'Minimum 2 characters are required')
      .max(50, 'Maximum 50 characters are allowed'),
    fax: phoneValidationSchema.concat(
      Yup.string()
        .required('Fax is required')
        .min(2, 'Minimum 2 characters are required')
        .max(50, 'Maximum 50 characters are allowed')
    ),
    departments: Yup.array().min(1, 'At least one department must be selected').required('Departments are required'),
  });

  const onCloseModal = (resetForm) => {
    handleClose();
    setAddress(null);
    setOpenGoogleMap(false);
    setAddressError('');
    resetForm();
    setTabValue(0)
    setSiteId(null)
    // setAddressDetails(null)
  };

  //used to set address details using address
  useEffect(() => {
    if (address) {
      setAddressError('');
      if (address?.value?.description) {
        geocodeByAddress(address?.label)
          .then((results) => {
            if (results.length !== 0) {
              return getLatLng(results[0]);
            } else {
              throw new Error('ZERO_RESULTS');
            }
          })
          .then(({ lat, lng }) => {
            setAddressDetails({ latitude: lat, longitude: lng, address: address.label });
          })
          .catch((error) => {
            if (error === 'ZERO_RESULTS') {
              setAddressError('');
            }
          });
      } else {
        setAddressDetails({ latitude: address.latLng.lat, longitude: address.latLng.lng, address: address.label });
      }
    } else {
      setAddressDetails();
    }
  }, [address]);

  const handleAddDepartment = async () => {
    if (dept) {
      if (
        deptMeta?.filter(
          (department) =>
            department.title.split(' ').join('').toLowerCase() ===
            dept
              .split(' ')
              .join('')
              .toLowerCase()
              .replace(/[^a-z0-9 ]/g, '')
        ).length !== 0
      ) {
        dispatch(
          openMessage({
            messageSeverity: 'warning',
            message: 'Department Already exist!',
          })
        );
      } else {
        const res = await addDepartmentInMeta(dept);
        if (res.data) {
          dispatch(openMessage({ messageSeverity: 'success', message: 'Department Added Successfully!' }));
          setShowAddDept(false);
          setDept('');
        }
      }
    } else {
      dispatch(openMessage({ messageSeverity: 'warning', message: 'Enter Department name!' }));
      setShowAddDept(false);
      setDept('');
    }
  };

  //use to open map
  const handleMapOpen = () => {
    setOpenGoogleMap(true);
  };

  // useEffect(()=>{
  // const geocoder = new window.google.maps.Geocoder();
  // if(selectedAddress.latLng){
  // console.log(selectedAddress)
  // geocoder.geocode({
  //   'latLng': selectedAddress.latLng
  // }, function (results, status) {
  //   if (status == window.google.maps.GeocoderStatus.OK) {
  //       if (results[1]) {
  //           setAddressDetails({ latitude: selectedAddress.latLng.lat(), longitude: selectedAddress.latLng.lng(), address: results[1].formatted_address })
  //           setAddress({label:results[1].formatted_address})
  //           setOpenGoogleMap(false);
  //       }
  //   }
  // });
  // }
  // else if(selectedAddress.placeId){
  //     geocoder
  //       .geocode({ placeId: selectedAddress.placeId })
  //       .then(({ results }) => {
  //           if (results[0]) {
  //               const myAddress = results[0].formatted_address;
  //               setAddressDetails({ latitude: selectedAddress.latLng.lat(), longitude: selectedAddress.latLng.lng(), address: myAddress })
  //               setAddress({label:myAddress})
  //               setOpenGoogleMap(false);
  //           }
  //       })
  // }

  // },[address])

  //to set address on done button
  const handleAddressOnDone = () => {
    if (currentAddress) {
      setAddress({
        label: currentAddress.label,
        latLng: currentAddress.latLng,
        value: { placeId: currentAddress.placeId ? currentAddress.placeId : null },
      });
      setOpenGoogleMap(false);
    }
  };

  //to close map
  const handleMapClose = () => {
    setOpenGoogleMap(false);
  };

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

  const saveSiteBankDetails = async (data) => {
    try {
      const payload = { ...data, businessType: 'company' };
      const res = await addSiteFinanceDetail({
        payload: payload,
        sponsorId,
        siteId
      }).unwrap();
      if (res) {
        openMessageNotification({
          message: 'Site Bank details added successfully.',
          type: 'success',
        });
        handleClose();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCreateSite = async (values, resetForm) => {
    values = { ...values, ...addressDetails };
    if (!address) {
      setAddressError('Address is required');
    } else {
      const result = await createSite({
        siteData: { ...values },
        sponsorId: sponsorId,
      });
      if (result.data) {
        openMessageNotification({
          message: 'Submitted Successfully',
          type: 'success',
        });
        setSiteId(result?.data?.id)
        setTabValue(1)
        // onCloseModal(resetForm);
      } else if (result.error) {
        if (result.error.data[0].message.includes('orgname', 'must be unique')) {
          openMessageNotification({
            message: 'Site with this name is already exists',
            type: 'error',
          });
        } else if (result.error.data[0].message.includes('fax', 'must be unique')) {
          openMessageNotification({
            message: 'Fax must be unique',
            type: 'error',
          });
        } else {
          openMessageNotification({
            message: 'Unable to Submit',
            type: 'error',
          });
        }
      }
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          orgname: '',
          fax: '',
          departments: [],
        }}
        validationSchema={docSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          await handleCreateSite(values, resetForm);
        }}
      >
        {({ isSubmitting, handleSubmit, handleChange, values, touched, errors, setValues, setFieldValue, resetForm }) => (
          <Dialog
            fullWidth
            maxWidth={"sm"}
            scroll={"paper"}
            open={open}
            onClose={() => {
              onCloseModal(resetForm);
            }}
            PaperProps={{
              sx: {
                maxWidth: '800px',
              },
            }}
          >
            <DialogTitle>Add Site</DialogTitle>
            <IconButton
              aria-label="close"
              onClick={() => {
                openGoogleMap ? handleMapClose() : onCloseModal(resetForm);
              }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent sx={{ pt: 0, width: { xs: 700, md: 800 } }}>
              <StyledTabs
                value={tabValue}
                onChange={handleTabChange}
                textColor="primary"
                indicatorColor="primary"
                variant="fullWidth"
                sx={{
                  top: 0,
                  pb: 2,
                }}
              >
                <Tab label="1. Site Details" {...a11yProps(0)} />
                <Tab label="2. Bank Details" {...a11yProps(1)} />
              </StyledTabs>
              <TabPanel value={tabValue} index={0}>
                {openGoogleMap ? (
                  <GoogleMapComponent setCurrentAddress={setCurrentAddress} openGoogleMap={openGoogleMap} />
                ) : (
                  <Form>
                    <Grid container spacing={4} pt={2}>
                      <Grid item xs={12} md={6}>
                        <TextField
                          label="Site Name"
                          onChange={handleChange}
                          name="orgname"
                          fullWidth
                          size="small"
                          value={values.orgname}
                          variant="outlined"
                          helperText={touched.orgname && errors.orgname}
                          error={touched.orgname && Boolean(errors.orgname)}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field name="fax" component={Phone} label="fax" />
                      </Grid>
                      <Grid item xs={12} sx={{ display: 'flex' }}>
                        {matchDownMD ? (
                          <Grid item xs={0.5} sx={{ mr: 1 }}>
                            <Box
                              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}
                            >
                              <Tooltip title={'Add Department'}>
                                <AddCircleOutlineIcon
                                  onClick={() => setShowAddDept(true)}
                                  sx={{ color: theme.palette.grey[600] }}
                                />
                              </Tooltip>
                            </Box>
                          </Grid>
                        ) : (
                          ''
                        )}
                        <Grid item xs={!matchDownMD ? 11 : 11.5}>
                          <FormControl
                            sx={{ width: '100%' }}
                            error={touched.departments && Boolean(errors.departments)}
                          >
                            <InputLabel sx={{ mt: -0.7 }}>Departments</InputLabel>
                            <Select
                              multiple
                              value={values.departments}
                              onChange={(event) => {
                                const { value } = event.target;
                                setFieldValue('departments', value);
                              }}
                              size="small"
                              label="Departments"
                              renderValue={(selected) => {
                                // Show department names based on their IDs
                                const selectedDepartments = deptMeta.filter((dept) => selected?.includes(dept.id));
                                return selectedDepartments.map((dept) => dept.title).join(', ');
                              }}
                              MenuProps={MenuProps}
                            >
                              {deptMeta?.map((deptMeta) => (
                                <MenuItem key={deptMeta.id} value={deptMeta.id}>
                                  <Checkbox checked={values?.departments?.includes(deptMeta.id)} />
                                  <ListItemText primary={deptMeta.title} />
                                </MenuItem>
                              ))}
                            </Select>
                            {touched.departments && errors.departments ? (
                              <FormHelperText>{errors.departments}</FormHelperText>
                            ) : null}
                          </FormControl>
                        </Grid>
                        {!matchDownMD ? (
                          <Grid item xs={1}>
                            <Box
                              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}
                            >
                              <Tooltip title={'Add Department'}>
                                <AddCircleOutlineIcon
                                  onClick={() => setShowAddDept(true)}
                                  sx={{ color: theme.palette.grey[600] }}
                                />
                              </Tooltip>
                            </Box>
                          </Grid>
                        ) : (
                          ''
                        )}
                      </Grid>
                      {showAddDept && (
                        <>
                          <Grid item xs={12} sx={{ display: 'flex' }}>
                            <TextField
                              value={dept}
                              onChange={(e) => setDept(e.target.value)}
                              label={'Add Department'}
                              size="small"
                              fullWidth
                              sx={{ width: '91.33%' }}
                            />
                            <Button color="primary" onClick={handleAddDepartment}>
                              {dept.length > 2 ? 'Add' : 'Cancel'}
                            </Button>
                          </Grid>
                        </>
                      )}
                      <Grid item xs={12} md={12} sx={{ display: 'flex' }}>
                        {matchDownMD ? (
                          <Grid item xs={0.5} sx={{ mr: 1 }}>
                            <Box
                              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}
                            >
                              <Tooltip title={'Select Location'}>
                                <PinDropRoundedIcon
                                  onClick={() => {
                                    handleMapOpen();
                                  }}
                                  sx={{ fontSize: '30px', color: theme.palette.grey[700] }}
                                />
                              </Tooltip>
                            </Box>
                          </Grid>
                        ) : (
                          ''
                        )}
                        <Grid item xs={!matchDownMD ? 11 : 11.5}>
                          <FormControl sx={{ width: '100%', height: '45px' }}>
                            <GooglePlacesAutocompleteComponent address={address} setAddress={setAddress} />
                            <Typography sx={{ fontSize: '12px', color: '#C62828', m: '4px 14px 0px 0px' }}>
                              {addressError}
                            </Typography>
                          </FormControl>
                        </Grid>
                        {!matchDownMD ? (
                          <Grid item xs={1}>
                            <Box
                              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}
                            >
                              <Tooltip title={'Select Location'}>
                                <PinDropRoundedIcon
                                  onClick={() => {
                                    handleMapOpen();
                                  }}
                                  sx={{ fontSize: '30px', color: theme.palette.grey[700] }}
                                />
                              </Tooltip>
                            </Box>
                          </Grid>
                        ) : (
                          ''
                        )}
                      </Grid>
                    </Grid>
                  </Form>
                )}
                <DialogActions>
                  {openGoogleMap ? (
                    <Box>
                      <CustomButton variant="outlined" onClick={() => handleMapClose()} sx={{ m: 2 }}>
                        Cancel
                      </CustomButton>
                      <CustomButton
                        variant="contained"
                        onClick={() => {
                          handleAddressOnDone();
                        }}
                        sx={{ m: 2 }}
                      >
                        Done
                      </CustomButton>
                    </Box>
                  ) : (
                    <Box sx={{width:'100%',display:'flex', justifyContent:'center', columnGap:2, pt:2}}>
                      <CustomButton variant="outlined" onClick={() => onCloseModal(resetForm)} >
                        Cancel
                      </CustomButton>
                      <CustomButton variant="contained" onClick={() => handleSubmit()} >
                        Save
                      </CustomButton>
                    </Box>
                  )}
                </DialogActions>
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <BankDetailsForm handleClose={handleClose} handleSave={saveSiteBankDetails} isLoading={isLoading}/>
              </TabPanel>
            </DialogContent>
          </Dialog>
        )}
      </Formik>
    </>
  );
};

export default AddSite;
