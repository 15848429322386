import React, { useEffect, useMemo, useState } from 'react';
import CustomCard from '../../components/@extended/CustomCard';
import { Table, TableBody, TableCell, TableContainer, TableRow, Typography, useTheme } from '@mui/material';
import RightArrow from '../../components/icons/RightArrow';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomTableHead, CustomTableHeadCell } from '../../components/@extended/CustomTable';
import { useSelector } from 'react-redux';

const columns = [
  {
    id: 'name',
    label: 'Site Name',
    minWidth: 170,
    align: 'left',
  },
  {
    id: 'email',
    label: 'Email',
    minWidth: 170,
    align: 'center',
  },
  {
    id: 'noOfSubject',
    label: 'Number of Subjects',
    minWidth: 170,
    align: 'center',
  },
  { id: 'status', label: 'Status', minWidth: 170, align: 'center' },

  { id: 'action', label: '', minWidth: 100,align: 'center' },
];

const FinanceSiteDetailsCard = () => {
  const { trialId } = useParams();
    const navigate = useNavigate();
    const theme = useTheme()
  const trialDetail = useSelector((state) => state.finance.trial);
  const rows = useMemo(() => {
    let rows = [];
    if (trialDetail) {
      trialDetail?.clinicalSites.map((site) => {
        const siteDetails = JSON.parse(site?.siteEntity?.addditionalInfo);
        const row = {};
        columns.forEach((column) => {
          switch (column.id) {
            case 'name':
              row[column.id] = siteDetails?.name;
              break;
            case 'status':
              row[column.id] = site?.siteEntity?.accountStatus;
              break;
            case 'noOfSubject':
              row[column.id] = site?.totalSubjectNumber;
              break;
            case 'email':
              row[column.id] = site?.siteEntity?.email;
              break;
            default:
              row[column.id] = site?.siteEntity[column.id];
              break;
          }
        });
        row['trialSiteId'] = site.id;
        row['siteId'] = site?.siteEntity?.id;
        rows.push(row);
      });
    }
    return rows;
  }, [trialDetail]);

  return (
      <CustomCard title="Sites">
          
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <CustomTableHead>
            <TableRow>
              {columns.map((column) => (
                <CustomTableHeadCell key={column.id + column.label + 'columns'} align={column.align}>
                  {column.label}
                </CustomTableHeadCell>
              ))}
            </TableRow>
          </CustomTableHead>
          <TableBody>
            {rows &&
              rows?.map((row, index) => {
                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={index}
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                      navigate(`/financeTrial/${trialId}/trial-site/${row.trialSiteId}/site/${row.siteId}`);
                    }}
                  >
                        {columns.map((column) => {
                            if (column.id === 'status') {
                            return <TableCell key={row[column.id] + column.label + index + 'Site data'} align={column.align}>
                            <Typography variant="subtitle2" color="initial" fontWeight={600} color={(row[column.id] === 'Active' || row[column.id] === 'active') ? theme.palette.success.main  : theme.palette.warning.main}>
                              {row[column.id]}
                            </Typography>
                            {column.id === 'action' && <RightArrow />}
                          </TableCell>
                        }
                      return (
                        <TableCell key={row[column.id] + column.label + index + 'Site data'} align={column.align}>
                          <Typography variant="subtitle2" color="initial">
                            {row[column.id]}
                          </Typography>
                          {column.id === 'action' && <RightArrow />}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            {rows.length === 0 && (
              <TableRow>
                <TableCell colSpan={columns?.length} align="center">
                  <Typography variant="subtitle2" color="initial">
                    No sites found.
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </CustomCard>
  );
};

export default FinanceSiteDetailsCard;
