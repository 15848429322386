import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../store/slices/authSlice';
import { getUserRole } from '../util';
import { CustomTableHead, CustomTableHeadCell, CustomTableRow } from '../../components/@extended/CustomTable';
import { Columns } from './TrialDetailColumns';
import { useNavigate } from 'react-router-dom';
import RightArrow from '../../components/icons/RightArrow';
import moment from 'moment';
import { useGetFinanceTrialsQuery } from '../../store/slices/apiSlice';

const FinanceTrialTable = () => {
  const user = useSelector(selectCurrentUser);
  const userRole = getUserRole(user);
  const sponsorDetails = useSelector((state) => state.userDetails.user);
  const theme = useTheme();
  const columns = Columns();
  const navigate = useNavigate();
  const { data } = useGetFinanceTrialsQuery(sponsorDetails?.financeDetail?.entityId, {
    skip: !sponsorDetails?.financeDetail?.entityId,
  });

  return (
    <>
      <Box sx={{ py: 2 }}>
        <Typography variant="h6" color="initial">
          Finance Trials
        </Typography>
      </Box>
      <Paper sx={{ width: '100%' }}>
        <Box
          sx={{
            width: '100%',
            height: 3,
            backgroundColor: theme.palette.primary.light,
          }}
        />
        <TableContainer sx={{ flexGrow: 1, overflow: 'auto', maxWidth: '100%' }}>
          <Table stickyHeader aria-label="sticky table">
            <CustomTableHead>
              <TableRow>
                {columns.map((column) => {
                  if (['totalBudget'].includes(column.id)) return;
                  return (
                    <CustomTableHeadCell key={column.id} align={column.align} sx={{ ...column?.style }}>
                      {column.label}
                    </CustomTableHeadCell>
                  );
                })}
              </TableRow>
            </CustomTableHead>
            <TableBody>
              {data && data.length > 0 ? (
                data.map((row) => {
                  return (
                    <CustomTableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                      onClick={() => {
                        row.status == 'Drafted' ? navigate(`/createFinanceTrial/${row.id}`) : navigate(`/financeTrial/${row.id}`);
                      }}
                      sx={{ cursor: 'pointer' }}
                    >
                          {columns.map((column) => {
                           if (['totalBudget'].includes(column.id)) return;
                        let value = null;
                        const style = { ...column?.style };
                        switch (column.id) {
                          case 'trialTitle':
                            value = row.title;
                            break;
                          case 'siteCount':
                            value = row?.clinicalSites?.length;
                            break;
                          case 'status': {
                            style.fontWeight = 600;
                            if (row.status === 'Created' || row.status === 'Ongoing') {
                              style.color = 'green';
                            } else {
                              style.color = 'orange';
                            }
                            value = row.status;
                            break;
                          }
                          default:
                            value = row[column.id];
                            break;
                        }

                        return (
                          <TableCell key={column.id} align={column.align}>
                            <Typography variant="subtitle2" color="initial" style={style}>
                              {value}
                            </Typography>
                            {column.id === 'action' && (
                              <RightArrow
                                onClick={() =>
                                  row.status == 'Drafted'
                                    ? navigate(`/createTrial/${row.id}`)
                                    : navigate(`/trial/${row.id}`)
                                }
                              />
                            )}
                          </TableCell>
                        );
                      })}
                    </CustomTableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    {userRole === 'PI' || userRole === 'site_coordinator' ? (
                      <Typography variant="subtitle1" color="initial">
                        No Created Trials Found
                      </Typography>
                    ) : (
                      <Typography variant="subtitle1" color="initial">
                        No Draft Trials Found
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default FinanceTrialTable;
