import React, { useMemo } from 'react';
import { Box, CardContent, Divider, Grid, Typography, useTheme } from '@mui/material';
import CustomCard from '../../../components/@extended/CustomCard';
import Label from '../../../components/common/Label';
import { useSelector } from 'react-redux';

const FinanceTrialSiteDetailsCard = () => {
  const theme = useTheme()
  const siteDetails = useSelector((state) => state.finance?.currentSite);
  const siteInfo = useMemo(() => {
    if (siteDetails) {
      if(siteDetails?.siteEntity?.addditionalInfo){
      return JSON.parse(siteDetails?.siteEntity?.addditionalInfo);} else return {}
    }
  }, [siteDetails]);

  return (
    <CustomCard title="Site Details">
      <CardContent sx={{py:0}}>
        <Grid container>
          <Grid item md={6} sm={12}>
            <Box sx={{ display: 'flex', py: 1.5 }}>
              <Label sx={{}}>Site Name :</Label>
              <Typography variant="subtitle2" color="initial">
                {siteInfo?.name ? siteInfo?.name : '--'}
              </Typography>
            </Box>
          </Grid>
          <Grid item md={6} sm={12}>
            <Box
              sx={{
                display: 'flex',
                py: 1.5,
              }}
            >
              <Label>Site Address :</Label>
              <Typography
                variant="subtitle2"
                color="initial"
                sx={{
                  maxWidth: '100%', 
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {siteInfo?.address?.line1}, {siteInfo?.address?.line2}, {siteInfo?.address?.city}, 
                {siteInfo?.address?.state}, {siteInfo?.address?.postal_code}, {siteInfo?.address?.country}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex' }}>
            <Grid container>
              <Grid item sm={12} md={6}>
                <Box sx={{ display: 'flex', py: 1.5 }}>
                  <Label sx={{}}>Contact :</Label>
                  <Typography variant="subtitle2" color="initial">
                    {siteInfo?.contact ? siteInfo?.contact : '--'}
                  </Typography>
                </Box>
              </Grid>
              <Grid item sm={12} md={6}>
                <Box
                  sx={{
                    display: 'flex',
                    py: 1.5,
                  }}
                >
                  <Label sx={{}}>Number of Subjects :</Label>
                  <Typography variant="subtitle2" color="initial">
                    {siteDetails?.totalSubjectNumber ? siteDetails?.totalSubjectNumber : '--'}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex' }}>
            <Grid container>
              <Grid item sm={12} md={6}>
                <Box sx={{ display: 'flex', py: 1.5 }}>
                  <Label sx={{}}>Status :</Label>
                  <Typography
                    variant="subtitle2"
                    color={
                      siteDetails?.siteEntity?.accountStatus === 'Pending'
                        ? theme.palette.warning.main
                        : theme.palette.success.main
                    }
                    fontWeight={600}
                  >
                    {siteDetails?.siteEntity?.accountStatus ? siteDetails?.siteEntity?.accountStatus : '--'}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </CustomCard>
  );
};

export default FinanceTrialSiteDetailsCard;
