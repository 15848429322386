import React, { useMemo } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Box, Divider, dividerClasses, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import CustomCard from "../../../components/@extended/CustomCard";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  indexAxis: "y",
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
  },
  options: {
    scales: {
      x: {
        ticks: {
          beginAtZero: true,
          max: 1000,
          min: 0,
        },
      },
      y: {
        ticks: {
          beginAtZero: false,
          max: 8,
          min: -3,
        },
      },
    },
  },
};
const FinanceSiteExpenditureChart = () => {
  const currentSite = useSelector((state)=> state.finance.currentSite)

  const totalExpenditure = useMemo(() => {
    let total = 0;
    if (!currentSite || !currentSite?.transactions) return total;

    for (const t of currentSite?.transactions) {
      total += t?.amount;
    }
    return total / 100;
  }, [currentSite]);

  const totalBudget = useMemo(() => {
    let total = 0;
    if (!currentSite || !currentSite?.events) return total;

    for (const ev of currentSite?.events) {
      for (const evpo of ev.eventPayoutDetails) {
        total += Number(evpo?.amount) * currentSite?.totalSubjectNumber
      }

      if (ev?.subjectPaymentAmount) {
        total += Number(ev?.subjectPaymentAmount) * currentSite?.totalSubjectNumber;
      }
    }
    return total;
  }, [currentSite])


    const data = useMemo(() => {
        return {
          chartData: {
            labels: [""],
            datasets: [
              {
                label: "Total Budget",
                data: [totalBudget],
                borderColor: "#FF4000",
                backgroundColor: "#FF4000",
              },
              {
                label: "Current Expenditure",
                data: [totalExpenditure],
                borderColor: '#602EB5',
                backgroundColor: '#602EB5',
              },
            ],
          },
        };
      });
  return (
    <CustomCard
    title={"Site Current Expenditure"}
    subHeader={"(all costs are in USD)"}
  >
    <Bar options={options} data={data.chartData} />
    <Box
          sx={{
            display: 'flex',
            columnGap: 2,
            justifyContent: 'center',
            // flexDirection: 'column',
            pt: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              pt: 2,
            }}
          >
            <Typography variant="subtitle1" color="initial">
              Total Budget
            </Typography>
            <Typography variant="h5" color="initial">
              ${totalBudget}
            </Typography>
          </Box>
          <Box
            sx={{
              height: '100%',
              [`& .${dividerClasses.root}`]: {
                mx: 0.5,
              },
            }}
          >
            <Divider orientation="vertical" flexItem />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              pt: 2,
            }}
          >
            <Typography variant="subtitle1" color="initial">
              Total Expenditure
            </Typography>
            <Typography variant="h5" color="initial">
              ${totalExpenditure}
            </Typography>
          </Box>
        </Box>
  </CustomCard>
  )
}

export default FinanceSiteExpenditureChart